import {
  IAbsencePolicyDTO,
  IOrganisationRatingDTO,
  ITrainingDTO,
  OrganisationDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import { ICostVM } from "../../../../pbdServices/services/Costs/models/cost-vm";
import { IUserVM } from "../../../../pbdServices/services/Users/userVM";

type AppUserColumns = keyof IUserVM | "tableRowActionColumn";

type OrganisationRatingColumns = keyof IOrganisationRatingDTO;

type OrganisationColumns = keyof OrganisationDTO;

type CostColumn = keyof ICostVM | "type" | "key";

type AbsencePolicyColumn = keyof IAbsencePolicyDTO;

type TrainingsColumns = keyof ITrainingDTO;

export default class VisibleColumns {
  /**
   * this includes title, tags, responsible
   */
  static readonly appTableVisibleColumns = ["title", "tags", "responsible"];

  static readonly articleAndFolderColumnsColumns = ["title", "responsible", "actions"];

  static readonly userTableColumns: AppUserColumns[] = [
    "tenant",
    "userName",
    "email",
    "emailConfirmed",
    "accessFailedCount",
    "tableRowActionColumn",
  ];

  static readonly organisationRatingColumns: OrganisationRatingColumns[] = [
    "id",
    "organisationRatingType",
    "organisation",
    "ratingValue",
    "organisation",
    "doneAt",
  ];

  static readonly organisationColumns: OrganisationColumns[] = ["title", "connectedElements"];

  static readonly trainingColumns: TrainingsColumns[] = ["title", "tags", "category", "responsible", "plannedAt"];

  static readonly costColumns: CostColumn[] = [
    "id",
    "type",
    "title",
    "category",
    "costCenter",
    "value",
    "module",
    "connectedTo",
    "duration",
    "key",
  ];

  static readonly absencePolicyColumns: AbsencePolicyColumn[] = ["title", "availableDays", "validFrom", "validTo"];
}
