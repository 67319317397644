import { Field, Form as FormFormik, Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ILoginDTO, ILoginResponseDTO, LoginDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { FormikCheckboxInput } from "../../shared/components/forms/formik/formikCheckboxInput";
import { FormikTextInput, FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { AccountRoutePaths } from "../accountRoutePaths";
import LoginValidationSummary from "./loginValidationSummary";

const nameof = nameofFactory<ILoginDTO>();

const getValidationSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<ILoginDTO> = yup.object({
    userName: yup
      .string()
      .required(t("This field is required"))
      // .email(t("Must be a valid email"))
      .max(100),
    password: yup.string().required(t("This field is required")).max(100),
    rememberMe: yup.boolean().required(),
  });
  return ValidationSchema;
};

interface IProps {
  returnUrl: string | undefined;
}

/**
 * Reload required after login for new antiforgery token.
 * See explanation here: https://learn.microsoft.com/en-us/aspnet/core/security/anti-request-forgery?view=aspnetcore-7.0#refresh-tokens-after-authentication
 */
function LoginForm(props: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { returnUrl } = props;
  const { accountApi } = useAPIs();
  const [rememberMe, setRememberMe] = React.useState(false);

  const actionAfterSubmit = (dto: ILoginResponseDTO) => {
    let href = "";
    let urlAfterLogin = returnUrl ?? "/";
    if (urlAfterLogin.toLowerCase().includes("error")) {
      urlAfterLogin = "/";
    }
    if (dto.requiresTwoFactor) {
      const rememberMeUrl = rememberMe ? `&rememberMe=1` : "";
      href = `${AccountRoutePaths.LoginWith2FaPage}?returnUrl=${urlAfterLogin}${rememberMeUrl}`;
      navigate(href);
    } else {
      href = urlAfterLogin;
      window.location.href = href;
    }
  };

  const submitter = useFormikAPISubmitter<ILoginDTO, ILoginResponseDTO>(
    (values) => {
      setRememberMe(values.rememberMe);
      return accountApi.login(new LoginDTO(values));
    },
    [accountApi],
    actionAfterSubmit,
  );

  const initialValues: ILoginDTO = { userName: "", password: "", rememberMe: true };
  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidationSchema(t)}>
      {(formikBag) => (
        <FormFormik>
          <FormikValidationSummary formikBag={formikBag} keysToExclude={["userName", "password"]} />
          <LoginValidationSummary formikBag={formikBag} />
          <FormikTextInputGroup name={nameof("userName")} autoComplete="username" label={t("Email or user name")} />
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t("Password")}</Form.Label>
            <Field name="password" component={FormikTextInput} type="password" autoComplete="current-password" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="rememberMe">
            <Field name="rememberMe" component={FormikCheckboxInput} id="rememberMe" label={t("Remember me?")} />
          </Form.Group>

          <Form.Group className="mb-3">
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                {formikBag.isSubmitting ? t("Loading...") : t("Sign in")}
              </Button>
            </div>
          </Form.Group>
          <div className="text-center">
            <p>
              <Link to={AccountRoutePaths.ForgotPasswordFn(formikBag.values.userName)}>{t("Forgot password?")}</Link>
            </p>
            <p>
              {t("You do not have an account?")}{" "}
              <Link to={AccountRoutePaths.Register}>{t("Register as a new user!")}</Link>
            </p>
          </div>
        </FormFormik>
      )}
    </Formik>
  );
}

export default LoginForm;
