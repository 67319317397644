import { useLocation, useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { ApiException } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { AccountRoutePaths } from "../../account/accountRoutePaths";
import { StartpageRoutePaths } from "../../startpage/startpageRoutePaths";

/**
 * This hook can be used in situations where it is not sure whether the user is authenticated or not.
 */
export const useMyProfileHook = () => {
  const { userApi } = useAPIs();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, mutate, isLoading } = useSWRImmutable("/api/me/user", () => userApi.getMe(), {
    onError(err, key, config) {
      if (err && err instanceof ApiException) {
        console.log(err);
        if (err.status == 401) {
          navigate(AccountRoutePaths.Login);
        } else if (err.status == 404) {
          navigate(AccountRoutePaths.ErrorPage.replace(":code", "404"));
        } else if (err.status == 400) {
          navigate(AccountRoutePaths.ErrorPage.replace(":code", "400"));
        }
      }
    },
    onErrorRetry(err, key, config, revalidate, revalidateOpts) {
      if (err && err instanceof ApiException) {
        // Never retry on 401
        if (err.status == 401) return;
      }
    },
    onSuccess(data, key, config) {
      if (data.isAuthenticated && location.pathname == AccountRoutePaths.Login) {
        window.location.href = StartpageRoutePaths.StartPage;
      } else if (!data.isAuthenticated && !location.pathname.startsWith("/Account")) {
        navigate(AccountRoutePaths.Login);
      }
    },
  });

  return { data, mutate, isLoading };
};
