/* eslint-disable @typescript-eslint/naming-convention */
import { IArticleDTO, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { ArticlesQueryParameters } from "../../pbdServices/services/Articles/models/query-parameters";
import RestUtilities from "../../services/restClients/restUtilities";

const routePrefix = "/";

export class ArticleRoutePaths {
  public static readonly HomePage = (module: PbdModule) => `/${module}`;

  public static readonly IndexPage = (module: PbdModule) => `/${module}/Index`;

  public static readonly IndexPageFn = (module: PbdModule, query?: ArticlesQueryParameters) =>
    `/${module}/Index/${RestUtilities.getQueryString(query)}`;

  public static readonly IndexPageMenuItems = (module: PbdModule) => `/${module}/MenuItems/Index`;

  public static readonly IndexPageMenuItemsFn = (module: PbdModule, parentMenuItemId?: number) => {
    const qs = RestUtilities.getQueryString({ parentMenuItemId });
    return `/${module}/MenuItems/Index/${qs}`;
  };
  public static readonly UnpublishedPage = (module: PbdModule) => `/${module}/Unpublished`;

  public static readonly DetailsPageMenuItems = (module: PbdModule) => `/${module}/MenuItems/Details/:id`;

  public static readonly IndexPageNews = (module: PbdModule) => `/${module}/News/Index`;

  public static Error(module: PbdModule): string {
    let base = module.toString();
    if (module == PbdModule.Blog) {
      base = base + "s";
    }
    if (module == PbdModule.KnowledgeBase) {
      base = base + "/" + base;
    }
    return `/${base}/Error/:code`;
  }

  public static readonly IndexDeletedPage = (module: PbdModule) => `/${module}/Index/?isDeleted=1`;

  public static readonly NotificationsPage = (module: PbdModule) => `/${module}/Notifications`;

  public static readonly DetailsPage = (module: PbdModule) => `/${module}/Details/:id`;

  public static readonly DetailsPageFn = (item: Pick<IArticleDTO, "id" | "module">) =>
    `/${item.module}/Details/:id`.replace(":id", item.id.toString());

  public static readonly DetailsPageRedirect = "/Articles/Details/:id";

  public static readonly CreatePage = (module: PbdModule) => `/${module}/Create`;

  public static readonly EditPage = (module: PbdModule) => `/${module}/Edit/:id`;

  public static readonly EditPageFn = (module: PbdModule, id: number) =>
    this.EditPage(module).replace(":id", id.toString());

  /**.../Details/:id/?_a=Edit-overview */
  public static readonly EditOverviewPage = (module: PbdModule) => `/${module}/Details/:id/?_a=Edit-overview`;

  public static readonly DocumentGraph = (module: PbdModule) => `/${module}/DocumentGraph`;

  public static readonly ComparePage = (module: PbdModule) => `/${module}/Compare`;

  public static readonly ReportingsPage = (module: PbdModule) => `/${module}/Reportings`;

  public static readonly ReviewsPage = (module: PbdModule) => `/${module}/Reviews`;

  public static readonly RatingsPage = (module: PbdModule) => `/${module}/Ratings`;

  public static readonly RatingsByArticlePage = (module: PbdModule) => `/${module}/Details/:id/Ratings`;

  public static readonly RecentPage = (module: PbdModule) => `/${module}/Recent`;

  public static readonly IndexArticleViewsPage = (module: PbdModule) => `/${module}/Views`;

  public static readonly ArticleViewsByArticlePage = (module: PbdModule) => `/${module}/Details/:id/Views`;

  public static readonly IndexTemplatesPage = (module: PbdModule) => `/${module}/Templates/Index`;

  public static readonly DetailsTemplatePage = (module: PbdModule) => `/${module}/Templates/Details/:id`;

  public static readonly StaticDocumentsIndex = `${routePrefix}${PbdModule.DocumentManagement}/StaticDocuments/Index`;

  public static readonly IndexArticleApprovalsPage = (module: PbdModule) => `/${module}/Approvals`;

  /**Medifox carenavigator customization */
  public static readonly CareNavigatorCertificate = `${routePrefix}${PbdModule.DocumentManagement}/CareNavigatorCertificate`;

  public static readonly EditBpmnPage = (module: PbdModule) => `/${module}/BpmnEditor/Edit/:id`;

  public static readonly EmployeeRecords = (module: PbdModule) => `/${module}/ReviewsNew`;

  public static readonly EditBpmnPageFn = (
    app: PbdModule,
    id: number,
    parentMenuItemId?: number,
    articleRevisionId?: number,
  ) => {
    const baseUrl = this.EditBpmnPage(app).replace(":id", id.toString());
    const qs = RestUtilities.getQueryString({ parentMenuItemId, articleRevisionId });
    return baseUrl + qs;
  };
}
