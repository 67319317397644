import { Field, Form as FormFormik, Formik, FormikProps } from "formik";
import { TFunction } from "i18next";
import { Alert, Button, ButtonGroup, ButtonToolbar, Card, Col, Collapse, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import * as yup from "yup";

import {
  IOpportunityCategoryDTO,
  IOpportunityCategoryFormulaDTO,
  IOpportunityCategoryVariable,
  OpportunityCategoryFormulaDTO,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { FormulaHelpers } from "../../../../Helpers/formula-helpers";
import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { getMaxResult, getMinResult } from "../../../../pbdServices/services/Calculator/getMinAndMaxResult";
import OpportunityCategoryService from "../../../../pbdServices/services/Opportunities/opportunityCategoryService";
import { nullableString } from "../../../../services/validation/stringSchemas";
import { FormikCheckboxInput } from "../../../shared/components/forms/formik/formikCheckboxInput";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import { FormikTextInput, FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";
import CollapseChevron from "../../../shared/components/icons/collapseChevron";
import { useToggle } from "../../../shared/hooks/useToggle";

const nameof = nameofFactory<IOpportunityCategoryFormulaDTO>();

const formulaRegex = new RegExp(/^[\w(+.?*|)@\-/^]+$/);

const getValidation = (t: TFunction, variables: IOpportunityCategoryVariable[]) => {
  const ValidationSchema: yup.ObjectSchema<IOpportunityCategoryFormulaDTO> = yup.object({
    formula: yup
      .string()
      .required()
      .formulaValid(variables, t("Formula invalid"))
      .matches(formulaRegex, t("Only letters, numbers and underscore allowed")),
    description: nullableString,
    maxResult: yup.number().required(),
    minResult: yup.number().required(),
    // setValuesManually: yup.boolean().notRequired(),
    formulaNeedsRefresh: yup.boolean().required(),
  });
  return ValidationSchema;
};

const setMinMaxValues = async (
  formikBag: FormikProps<IOpportunityCategoryFormulaDTO>,
  newFormula: string,
  manualValues: boolean,
  variables: IOpportunityCategoryVariable[],
) => {
  const { setFieldValue, setFieldTouched } = formikBag;
  if (!manualValues) {
    const isValid = FormulaHelpers.isValid(newFormula, variables);
    await setFieldTouched(nameof("formula"));
    if (isValid.isValid) {
      setFieldValue(nameof("minResult"), getMinResult(FormulaHelpers.sanitizeFormula(newFormula), variables));
      setFieldValue(nameof("maxResult"), getMaxResult(FormulaHelpers.sanitizeFormula(newFormula), variables));
    }
  }
};

interface IProps {
  itemToUpdate: IOpportunityCategoryDTO;
  refreshParent: () => void;
}

function FormulaCard(props: IProps) {
  const { itemToUpdate, refreshParent } = props;
  const { t } = useTranslation();
  const { opportunityCategoriesApi } = useAPIs();
  const [manualValues, toggleManualValues] = useToggle(false);
  const [isOpen, setIsOpen] = useQueryParam("formula", withDefault(BooleanParam, false));

  const toggle = () => setIsOpen(!isOpen);

  const submitter = useFormikAPISubmitter<IOpportunityCategoryFormulaDTO>(
    (val) => opportunityCategoriesApi.updateFormula(itemToUpdate.id, new OpportunityCategoryFormulaDTO(val)),
    [itemToUpdate.id, opportunityCategoriesApi],
    refreshParent,
  );

  const initialValues: IOpportunityCategoryFormulaDTO = {
    formula: itemToUpdate.formulaInfo?.formula ?? "",
    description: itemToUpdate.formulaInfo?.description ?? "",
    maxResult: itemToUpdate.formulaInfo?.maxResult ?? 0,
    minResult: itemToUpdate.formulaInfo?.minResult ?? 0,
    // setValuesManually: itemToUpdate.formulaInfo.setValuesManually || false,
    formulaNeedsRefresh: itemToUpdate.formulaInfo?.formulaNeedsRefresh ?? false,
  };
  return (
    <>
      <Card.Header>
        <Card.Title as="h5">
          <a href="#formulaCard" onClick={toggle}>
            <span>
              <CollapseChevron isOpen={isOpen} /> 3. {t("Formula")}
            </span>
          </a>
        </Card.Title>
      </Card.Header>
      <Collapse in={isOpen}>
        <div id="formulaCard">
          <Card.Body>
            {itemToUpdate.formulaInfo?.formulaNeedsRefresh && (
              <Alert color="warning">{t("Formula needs to be updated")}</Alert>
            )}
            <Formik
              initialValues={initialValues}
              onSubmit={submitter}
              validate={(values) => {
                if (!manualValues) {
                  OpportunityCategoryService.validateFormulaForm(values, itemToUpdate);
                }
              }}
              validationSchema={getValidation(t, itemToUpdate.variables ?? [])}
            >
              {(formikBag) => (
                <FormFormik>
                  <FormikDebugInfo formikBag={formikBag} />
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Operators")}</Form.Label>
                    <ButtonToolbar>
                      <ButtonGroup>
                        {FormulaHelpers.arithmeticOperators.map((x) => (
                          <Button
                            key={x}
                            type="button"
                            onClick={async () => {
                              const newFormula = `${formikBag.values.formula}${x}`;
                              await formikBag.setFieldValue(nameof("formula"), newFormula);
                              setMinMaxValues(formikBag, newFormula, manualValues, itemToUpdate.variables ?? []);
                            }}
                          >
                            {x}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Variables")}</Form.Label>
                    <ButtonToolbar>
                      <ButtonGroup>
                        {itemToUpdate.variables?.map((x) => (
                          <Button
                            key={x.variableId}
                            type="button"
                            variant="primary"
                            onClick={async () => {
                              const newFormula = `${formikBag.values.formula}@${x.variableName}`;
                              await formikBag.setFieldValue(nameof("formula"), newFormula);
                              setMinMaxValues(formikBag, newFormula, manualValues, itemToUpdate.variables ?? []);
                            }}
                          >
                            @{x.variableName}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Form.Group>
                  <FormikTextInputGroup name={nameof("formula")} />
                  <FormikTextInputGroup name={nameof("description")} textarea />
                  <Alert variant="info">
                    {t(
                      "Min/Max Results for complex formulas may not be calculated correctly. To manually set these values check the checkbox below.",
                    )}
                  </Alert>
                  <Form.Group className="mb-3">
                    <Field
                      name="setValuesManually"
                      id="setValuesManually"
                      component={FormikCheckboxInput}
                      onClick={toggleManualValues}
                      label={t("Set min/max values manually")}
                    />
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group controlId={nameof("minResult")} className="mb-3">
                        <Form.Label>{t("Minimum result")}</Form.Label>
                        <Field name={nameof("minResult")} component={FormikTextInput} disabled={!manualValues} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={nameof("maxResult")} className="mb-3">
                        <Form.Label>{t("Maximum result")}</Form.Label>
                        <Field name={nameof("maxResult")} component={FormikTextInput} disabled={!manualValues} />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button variant="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                    {t("Save")}
                  </Button>
                  <FormikValidationSummary formikBag={formikBag} />
                </FormFormik>
              )}
            </Formik>
          </Card.Body>
        </div>
      </Collapse>
    </>
  );
}

export default FormulaCard;
