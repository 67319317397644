import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { CustomFieldType, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

interface IProps {
  customFieldId: string;
  app: PbdModule;
  onChange: (key: string, val: string) => void;
  defaultValue?: string;
}

function CustomFieldSearchInputComponent(props: IProps) {
  const { t } = useTranslation();
  const { customFieldsApi } = useAPIs();
  const { customFieldId, app, onChange } = props;
  const { data } = useSWR(["/api/customFields", customFieldId, app], () =>
    customFieldsApi.getCustomFieldByModuleAndId(app, customFieldId),
  );

  if (!data) return null;

  return (
    <Form.Group className="mb-3" controlId={data.id}>
      <Form.Label>{data.name}</Form.Label>
      {data.type == CustomFieldType.Choices && (
        <Form.Select
          name={data.id}
          defaultValue={props.defaultValue}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        >
          <option value="">{t("Please select")}</option>
          {data.options?.map((x) => (
            <option value={x.name} key={x.name}>
              {x.name}
            </option>
          ))}
        </Form.Select>
      )}
      {data.type != CustomFieldType.Choices && (
        <Form.Control
          type="text"
          name={data.id}
          defaultValue={props.defaultValue}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      )}
    </Form.Group>
  );
}
export default CustomFieldSearchInputComponent;
