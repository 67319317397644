import { Field, FieldProps, Form, Formik } from "formik";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormFeedback, FormGroup, FormTextProps, Label } from "reactstrap";
import * as yup from "yup";

import { IAbsencePolicyCreateDTO, IAbsencePolicyDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import CancelButton from "../../../shared/components/buttons/cancelButton";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import { FormikHtmlInput } from "../../../shared/components/forms/formik/formikHtmlInput";
import { FormikLuxonDateInput } from "../../../shared/components/forms/formik/formikLuxonDateInput";
import { FormikNumberInput } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";
import DateRangeFilter from "../../../shared/components/genericSearchFilter/dateRangeFilter";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import AbsenceTypeSelect from "../../../shared/components/inputControl/select/absenceTypeSelect";
import { useToggle } from "../../../shared/hooks/useToggle";

type LocalType = Pick<IAbsencePolicyCreateDTO, "validFrom" | "validTo" | "residualExpirationDate">;
//@ts-expect-error TODO: Fix with better date types
const dateSchema: yup.ObjectSchema<LocalType> = yup.object({
  residualExpirationDate: yup.date().notRequired(),
  validFrom: yup.date().required(),
  validTo: yup.date().required(),
});

const ValidationSchema: yup.ObjectSchema<IAbsencePolicyCreateDTO> = yup
  .object({
    title: yup.string().required().min(2).max(250),
    availableDays: yup.number(),
    absenceTypeId: yup.number().required().min(1),
    absencePolicyParentId: yup.number(),
    description: yup.string(),
    residualDays: yup.number().transform((val) => (isNaN(val) || val == null || val == undefined ? undefined : val)),
    absencePolicyApproverIds: yup.array().of(yup.number()),
  })
  .concat(dateSchema);

const nameof = nameofFactory<IAbsencePolicyCreateDTO>();

interface IProps extends BaseFormProps<IAbsencePolicyDTO, IAbsencePolicyCreateDTO, IAbsencePolicyDTO | void> {
  onCancel: () => void;
  //   onSubmit:(dto:IAbsenceRequestCreateDTO)=>Promise<IAbsenceRequestDTO>;
  onSuccess: (dto: IAbsencePolicyDTO) => void;
}

function CreateFormAbsencePolicy(props: IProps) {
  const { t } = useTranslation();
  const { onSubmit, onCancel, onSuccess, itemToUpdate } = props;
  const [residualConfig, toggleResidualConfig] = useToggle(itemToUpdate?.residualExpirationDate != undefined);

  const submitter = useFormikAPISubmitter<IAbsencePolicyCreateDTO, IAbsencePolicyDTO | void>(
    (val) => onSubmit(val),
    [onSubmit],
    (resp) => {
      if (resp) {
        onSuccess(resp);
      } else if (itemToUpdate) {
        onSuccess(itemToUpdate);
      } else {
        throw Error("Not implemented");
      }
    },
  );

  const initialValues: IAbsencePolicyCreateDTO = {
    title: itemToUpdate?.title ?? "",
    validFrom: itemToUpdate?.validFrom ?? DateTime.now().startOf("year"),
    validTo: itemToUpdate?.validTo ?? DateTime.now().endOf("year"),
    absenceTypeId: itemToUpdate?.absenceType?.id ?? 0,
    residualDays: itemToUpdate?.residualDays,
    availableDays: itemToUpdate?.availableDays ?? undefined,
    residualExpirationDate: itemToUpdate?.residualExpirationDate,
  };
  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <Form>
          <FormikDebugInfo formikBag={formikBag} />
          <FormGroup>
            <Label for={nameof("title")}>{t("Title")}</Label>
            <Field name={nameof("title")} component={FormikTextInput} />
          </FormGroup>
          <FormGroup>
            <Label>{t("Absence type")}</Label>
            <Field name={nameof("absenceTypeId")}>
              {({ field, form: { setFieldValue, setFieldTouched }, meta }: FieldProps<string> & FormTextProps) => (
                <React.Fragment>
                  <AbsenceTypeSelect
                    onChange={(x) => setFieldValue(field.name, x)}
                    selectedIds={itemToUpdate?.absenceType != null ? [itemToUpdate.absenceType.id] : []}
                    onBlur={() => setFieldTouched(field.name)}
                  />
                  {meta.touched && meta.error && (
                    <FormFeedback name={field.name} style={{ display: "block" }}>
                      {t("This field is required")}
                    </FormFeedback>
                  )}
                </React.Fragment>
              )}
            </Field>
          </FormGroup>
          <DateRangeFilter
            label={t("Valid")}
            from={formikBag.values.validFrom}
            setFrom={(x) => formikBag.setFieldValue("validFrom", x)}
            to={formikBag.values.validTo}
            setTo={(x) => formikBag.setFieldValue("validTo", x)}
          />
          <FormGroup>
            <Label for={nameof("availableDays")}>{t("Available days")}</Label>
            <Field
              name={nameof("availableDays")}
              component={FormikNumberInput}
              formText={t("Leave empty for unlimited")}
            />
          </FormGroup>
          <FormGroup>
            <Label for={nameof("description")}>{t("Description")}</Label>
            <Field name={nameof("description")} component={FormikHtmlInput} />
          </FormGroup>
          {!residualConfig && (
            <Button onClick={toggleResidualConfig} color="link" className="mb-3">
              <qmBaseIcons.LogInspection /> {t("Set remaining leave")}
            </Button>
          )}
          {residualConfig && (
            <>
              <h5>{t("Remaining leave")}</h5>
              <FormGroup>
                <Label for={nameof("residualDays")}>{t("Residual days")}</Label>
                <Field name={nameof("residualDays")} component={FormikNumberInput} />
              </FormGroup>
              <FormGroup>
                <Label for={nameof("residualExpirationDate")}>{t("Residual expiration period")}</Label>
                <Field name={nameof("residualExpirationDate")} component={FormikLuxonDateInput} />
              </FormGroup>
            </>
          )}
          <FormGroup>
            <CancelButton onClick={onCancel} />
            <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
              {t("Save")}
            </Button>
          </FormGroup>
          <FormikValidationSummary formikBag={formikBag} />
        </Form>
      )}
    </Formik>
  );
}
export default CreateFormAbsencePolicy;
