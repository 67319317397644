import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, CardTitle, FormGroup, FormText, Label } from "reactstrap";
import * as yup from "yup";

import { IExternalIdSetting, IModuleSettingDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { FormikCheckboxInput } from "../../shared/components/forms/formik/formikCheckboxInput";
import FormikDebugInfo from "../../shared/components/forms/formik/formikDebugInfo";
import { FormikTextInput } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { toastSuccess } from "../../shared/components/toasts/toastSuccess";

const nameof = nameofFactory<IExternalIdSetting>();

const ValidationSchema: yup.ObjectSchema<IExternalIdSetting> = yup.object({
  isUsed: yup.boolean().required(),
  isRequired: yup.boolean().required(),
  displayName: yup.string().max(250),
  hideInternalId: yup.boolean().required(),
});

interface IProps {
  itemToUpdate?: IExternalIdSetting;
  onSubmit: (dto: IExternalIdSetting) => Promise<IModuleSettingDTO>;
}

const ExternalIdForm: React.FC<IProps> = (props) => {
  const { itemToUpdate, onSubmit } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IExternalIdSetting, IModuleSettingDTO>(
    (val) => onSubmit(val),
    [onSubmit],
    () => toastSuccess(t("Saved")),
  );

  const initialValues: IExternalIdSetting = {
    displayName: itemToUpdate?.displayName || "",
    isRequired: itemToUpdate?.isRequired || false,
    isUsed: itemToUpdate?.isUsed || false,
    hideInternalId: itemToUpdate?.hideInternalId || false,
  };

  return (
    <React.Fragment>
      <CardTitle tag="h5">{t("External ID")}</CardTitle>
      <hr />
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
        {(formikBag) => (
          <Form>
            <FormikDebugInfo formikBag={formikBag} />
            <FormGroup>
              <Field name={nameof("isUsed")} id="isUsed" component={FormikCheckboxInput} label={t("Is used")} />
            </FormGroup>
            {formikBag.values.isUsed && (
              <React.Fragment>
                <FormGroup>
                  <Label for="displayName">{t("Display name")}</Label>
                  <Field name="displayName" component={FormikTextInput} />
                </FormGroup>
                <FormGroup>
                  <Field name="isRequired" id="isRequired" component={FormikCheckboxInput} label={t("Is required")} />
                  <FormText color="muted">
                    {t("Setting the external ID as required, users will be prompted to fill out this field.")}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Field
                    name="hideInternalId"
                    id="hideInternalId"
                    component={FormikCheckboxInput}
                    label={t("Hide internal ID")}
                  />
                  <FormText color="muted">
                    {t("We try to hide the internal ID wherever possible to prevent the irritation of multiple IDs")}
                  </FormText>
                </FormGroup>
              </React.Fragment>
            )}

            <FormGroup>
              <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                {t("Save")}
              </Button>
            </FormGroup>

            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default ExternalIdForm;
