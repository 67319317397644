import { t } from "i18next";
import { uniq } from "lodash";
import { DateTime, Duration } from "luxon";

import {
  EntityKey,
  GoalUnitDTO,
  GoalUnitType,
  ICostDTO,
  IGoalDTO,
  IGoalProgressDTO,
  IGoalProgressesControllerClient,
  IGoalsControllerClient,
  IToDoDTO,
  PbdModule,
  PbdStatus,
} from "@generatedCode/pbd-core/pbd-core-api";

import { GoalRoutePaths } from "../../../ClientApp/goals/goalRoutePaths";
import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { ChartHelpers } from "../../../Helpers/ChartHelpers";
import { NumberHelpers } from "../../../Helpers/NumberHelpers";
import StringHelpers from "../../../Helpers/StringHelpers";
import RestUtilities from "../../../services/restClients/restUtilities";
import { AppNotifications } from "../../Models/Notifications/AppNotifications";
import { NotificationItem } from "../../Models/Notifications/ModuleNotification";
import CostService from "../Costs/costService";
import CustomFieldService from "../CustomFields/customFieldService";
import { ExportDTOService } from "../Export/exportDTOService";
import ExportService, { ExportData } from "../Export/exportService";
import ToDoService from "../ToDos/todoService";
import { GoalKpis } from "./models/goal-kpis";
import { IGoalVm } from "./models/goal-vm";
import { IGoalWithProgress } from "./models/goal-with-progress";
import { GoalQueryParameters, GoalQueryParametersWithLocal } from "./models/query-parameters";

export default class GoalService extends ExportDTOService<IGoalDTO> {
  private readonly _goalsApi: IGoalsControllerClient;
  private readonly _goalsProgressApi: IGoalProgressesControllerClient;
  private readonly _activeStatus = [PbdStatus.Open, PbdStatus.InProgress];
  constructor(goalsApi: IGoalsControllerClient, goalsProgressApi: IGoalProgressesControllerClient) {
    super();
    this._goalsApi = goalsApi;
    this._goalsProgressApi = goalsProgressApi;
  }

  async getAllWithProgress(query: GoalQueryParametersWithLocal): Promise<IGoalVm[]> {
    const goals = await this._goalsApi.getAllQuery(query);
    const progress = await this._goalsProgressApi.getAllQuery({ goalId: goals.map((x) => x.id) });
    let mappedResult = this.mapToVm(goals, progress);
    const { nextInspectionTo, customFields } = query;
    if (nextInspectionTo) {
      mappedResult = mappedResult.filter(
        (x) => x.latestProgress?.nextInspection && x.latestProgress.nextInspection < nextInspectionTo,
      );
    }
    if (customFields) {
      mappedResult = CustomFieldService.filterByCustomFields(mappedResult, query.customFields);
    }
    return mappedResult;
  }

  async getAllNotificationsAsAppNotification(tenantId: number) {
    const query: GoalQueryParameters = { status: [PbdStatus.Open, PbdStatus.InProgress], responsibleId: [tenantId] };
    const all = await this.getAllWithProgress(query);

    const notificationList: NotificationItem<IGoalVm>[] = [
      new NotificationItem<IGoalVm>(
        "Goal deadline expired",
        GoalRoutePaths.IndexPage + RestUtilities.getQueryString({ ...query, deadlineTo: DateTime.now() }, true),
        all.filter((x) => x.deadline < DateTime.now()),
        undefined,
        "Complete this task, shift the deadline or comment your current progress",
      ),
      new NotificationItem<IGoalVm>(
        "Goal progress must be tracked",
        GoalRoutePaths.IndexPage + RestUtilities.getQueryString({ ...query, nextInspectionTo: DateTime.now() }, true),
        all.filter((x) => x.latestProgress?.nextInspection && x.latestProgress.nextInspection < DateTime.now()),
        undefined,
        "Track the current progress of these goals",
      ),
    ];
    // return new AppNotifications(app, []);
    return new AppNotifications(PbdModule.GoalManagement, notificationList);
  }

  async getMyTasks(tenantId: number) {
    const myResponsible = await this._goalsApi.getAllQuery({ status: this._activeStatus, responsibleId: [tenantId] });
    const myCreated = await this._goalsApi.getAllQuery({ status: this._activeStatus, createdById: [tenantId] });
    let showCreateAlert = myCreated.length == 0 && myResponsible.length == 0;
    if (myCreated.length == 0) {
      const anyCreated = await this._goalsApi.getAllQuery({ createdById: [tenantId] });

      showCreateAlert = anyCreated.length == 0;
    }
    return { myCreated, myResponsible, showCreateAlert };
  }

  static targetAnnotation(goal: IGoalVm | undefined) {
    if (!goal?.valueGoal) return undefined;
    return NumberHelpers.isNumber(goal.valueGoal)
      ? [
          ChartHelpers.getChartAnnotations(
            `${t("Goal value")}: ${Number(goal.valueGoal)} ${goal.valueUnit?.unitInfo}`,
            Number(goal.valueGoal),
          ),
        ]
      : undefined;
  }

  mapProgressToGoal(goals: IGoalDTO[], goalProgresses: IGoalProgressDTO[]): IGoalWithProgress[] {
    //Sort by doneAt
    goalProgresses.sort((a, b) => +b.doneAt - +a.doneAt);

    return goals.map((goal) => {
      const progresses = goalProgresses.filter((x) => x.goalId == goal.id);
      const nextProgressAt = progresses[0]?.nextInspection;

      return {
        ...goal,
        progresses,
        nextProgressAt,
      };
    });
  }

  mapToVm(goals: IGoalDTO[], goalProgresses: IGoalProgressDTO[]): IGoalVm[] {
    //Sort by doneAt
    goalProgresses.sort((a, b) => +b.doneAt - +a.doneAt);

    return goals.map((goal) => {
      const progresses = goalProgresses.filter((x) => x.goalId == goal.id);
      const nextProgressAt = progresses[0]?.nextInspection;

      return {
        ...goal,
        progresses,
        nextProgressAt,
        latestProgress: progresses[0],
        earliestProgress: progresses[progresses.length - 1],
      };
    });
  }

  exportToCsv(items: IGoalVm[]) {
    return ExportService.exportCSV("Goals", items, (x) => ({
      id: x.id,
      title: x.title,
      status: x.status,
      unit: `${x.valueUnit?.unit}`,
      unitInfo: `${x.valueUnit?.unitInfo}`,
      startValue: `${x.valueStart}`,
      ["[Obsolete] currentValue"]: `${x.valueCurrent}`,
      goalValue: `${x.valueGoal}`,
      direction: x.goalDirection,
      responsible: x.responsible?.fullName,
      createdAt: x.createdAt,
      deadline: x.deadline,
      latestGoalProgress_progress: x.latestProgress?.progress,
      latestGoalProgress_doneAt: x.latestProgress?.doneAt,
    }));
  }

  mapExport(x: IGoalDTO): ExportData {
    return {
      id: x.id,
      title: x.title,
      status: x.status,
      unit: `${x.valueUnit?.unit}`,
      unitInfo: `${x.valueUnit?.unitInfo}`,
      startValue: `${x.valueStart}`,
      currentValue: `${x.valueCurrent}`,
      goalValue: `${x.valueGoal}`,
      direction: x.goalDirection,
      responsible: x.responsible?.fullName,
      createdAt: x.createdAt.toISO(),
      deadline: x.deadline.toISO(),
    };
  }
  getExportName(): string {
    return "Goals";
  }

  static getKpis(
    all: IGoalDTO[],
    connectedTodos: IToDoDTO[],
    progresses: IGoalProgressDTO[],
    costs: ICostDTO[],
    totalUrl?: string,
  ) {
    const withCosts = CostService.mapWithCosts(all, costs ?? []);
    const kpis = new GoalKpis(withCosts, totalUrl);
    kpis.connectedTodos = ToDoService.getKpis(connectedTodos);
    kpis.progresses = progresses;
    return kpis;
  }

  static mapToDynamicDataset(data: IGoalVm[]) {
    const goalIds = uniq(data.map((x) => x.id));
    const distinctGoals: { id: number; title: string }[] = goalIds.map((x) => {
      return { id: x, title: data.find((f) => f.id == x)!.title };
    });
    const progressTotal = data.filterMap((x) => x.progresses).reduce((pv, cv) => pv.concat(cv ?? []), []);
    const dates = uniq(progressTotal.filterMap((x) => x.doneAt.toISODate()));
    const dataset: Dataset = { columns: distinctGoals, rows: [] };
    const dataRows: DataRow[] = [];
    for (const d of dates) {
      const rowToAdd: DataRow = { id: d, primaryKey: DateTime.fromISO(d), values: {} };

      for (const g of distinctGoals) {
        const progress = progressTotal.find((x) => x.goalId == g.id && x.doneAt.toISODate() == d);
        rowToAdd.values[`#${g.id}`] = progress?.progress;
      }
      dataRows.push(rowToAdd);
    }
    dataset.rows = dataRows;
    return dataset;
  }

  static getStateForProgressCard(data: IGoalVm[] | undefined) {
    if (!data) return undefined;
    if (data.length == 0) return "MissingData";
    if (data.length == 1) return "One";
    const progressCount = data.reduce((pv, cv) => pv + (cv.progresses ?? []).length, 0);
    if (progressCount < 2) return "NotEnoughData";
    return "OK";
  }

  static calculateNextInspection(goal: IGoalDTO, progress?: IGoalProgressDTO) {
    if (progress) return progress.nextInspection;
    if (goal.isRecurring && goal.monitoringInterval?.timeSpanISO) {
      const duration = Duration.fromISO(goal.monitoringInterval.timeSpanISO);
      return DateTime.now().plus(duration);
    }
    return undefined;
  }

  static get availableFilters() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.CreatedBy,
      SearchFilterTypes.Deadline,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.CustomField,
      SearchFilterTypes.NextInspectionAt,
    ];
  }

  static get getConnections() {
    return [
      new AvailableConnection(EntityKey.Goal, undefined, undefined, { includeParent: true }),
      new AvailableConnection(EntityKey.Audit),
      new AvailableConnection(EntityKey.ToDo),
      new AvailableConnection(EntityKey.Opportunity),
    ];
  }

  static getUnitIcon(unit?: GoalUnitDTO) {
    const mapped: Record<GoalUnitType, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      None: "Missing unit",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Number: "#",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Percent: "%",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      PerMille: "‰",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      PerMillion: "ppm",
    };
    return mapped[unit?.unit ?? "None"];
  }

  static hideLegacyProgress(dto: { valueCurrent?: string | undefined; valueStart?: string | undefined }) {
    return StringHelpers.isNullOrWhitespace(dto.valueCurrent) && StringHelpers.isNullOrWhitespace(dto.valueStart);
  }
}

interface Column {
  id: number;
  title: string;
}

interface Dataset {
  rows: DataRow[];
  columns: Column[];
}

export interface DataRow {
  id: string;
  primaryKey: DateTime;
  values: Record<string, number | undefined>;
}
