import { Field, Form as FormFormik, Formik } from "formik";
import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  HttpVerbs,
  IFileDTO,
  ILinkObject,
  IQuickAccessLink,
  LinkObject,
  PbdModule,
  SettingType,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import AppInfoIconComponent from "../../appInfo/components/appInfoIconComponent";
import CancelSubmitFormGroup from "../../shared/components/buttons/cancelSubmitFormGroup";
import CustomCropper from "../../shared/components/fileUpload/customCropper";
import { FormikCheckboxInput } from "../../shared/components/forms/formik/formikCheckboxInput";
import { FormikTextInput } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import IdComponent from "../../shared/components/id/idComponent";
import { useToggle } from "../../shared/hooks/useToggle";

type LocalDateType = Pick<IQuickAccessLink, "createdAt">;
const nameof = nameofFactory<IQuickAccessLink>();

//@ts-expect-error : Default date error
const dateSchema: yup.ObjectSchema<LocalDateType> = yup.object({
  createdAt: yup.date().required(),
});

const linkSchema: yup.ObjectSchema<ILinkObject> = yup.object({
  href: yup.string().required().min(2).max(250),
  rel: yup.string().required(),
  method: yup.mixed<HttpVerbs>().oneOf(Object.values(HttpVerbs)).required(),
});

//@ts-expect-error TOOD: Leads to error in non strict mode
const QuickAccessSchema: yup.ObjectSchema<IQuickAccessLink> = yup
  .object({
    title: yup.string().required().min(2).max(250),
    id: yup.string().required(),
    orderNumber: yup.number().required(),
    description: yup.string(),
    link: linkSchema,
    isFavorite: yup.boolean().required(),
    thumbnailId: yup.number(),
    createdById: yup.number().required(),
    isShared: yup.boolean().required(),
  })
  .concat(dateSchema);

interface IProps {
  module: PbdModule;
  refreshParent: () => void;
  itemToUpdate?: IQuickAccessLink;
  toggle: () => void;
  data: IQuickAccessLink[];
}

function EditFormQuickAccess(props: IProps) {
  const { t } = useTranslation();
  const { itemToUpdate, toggle, refreshParent, module, data } = props;
  const { moduleSettingsService } = useAPIServices();
  const { blobsApi } = useAPIs();
  const [editThumbnailMode, toggleEditThumbnailMode] = useToggle();

  async function onSubmit(dto: IQuickAccessLink) {
    const valueNew = [...data];
    const index = data.findIndex((x) => x.id == dto.id);
    if (index > -1) {
      valueNew[index] = dto;
    } else {
      valueNew.push(dto);
    }
    const resp = await wrapApiCallWithToast(() =>
      moduleSettingsService.save(module, SettingType.QuickAccessLinks, valueNew),
    );
    if (resp.isOk) {
      toggle();
      refreshParent();
    }
    return resp;
  }

  const initialValues: IQuickAccessLink = {
    id: itemToUpdate?.id ?? nanoid(),
    title: itemToUpdate?.title ?? "",
    description: itemToUpdate?.description ?? "",
    link: itemToUpdate?.link ?? new LinkObject({ href: "", rel: "self", method: HttpVerbs.GET }),
    orderNumber: 0,
    isFavorite: itemToUpdate?.isFavorite ?? false,
    thumbnailId: itemToUpdate?.thumbnailId ?? 0,
    createdAt: itemToUpdate?.createdAt ?? DateTime.now(),
    createdById: itemToUpdate?.createdById ?? 0,
    isShared: itemToUpdate?.isShared ?? false,
  };

  const saveUploadedThumbnailId = async (file: IFileDTO) => {
    if (!itemToUpdate) throw Error("Missing data");
    const submitData = { ...itemToUpdate, thumbnailId: file.id };
    await onSubmit(submitData);
    toggleEditThumbnailMode();
  };

  const handleUpload = async (blob: Blob, fileName: string) => {
    const resp = await blobsApi.create(
      "file",
      { data: blob, fileName: fileName },
      "",
      `${PbdModule.Startpage}/${SettingType.QuickAccessLinks}/${itemToUpdate?.id}/`,
      true,
    );
    saveUploadedThumbnailId(resp);
  };

  return (
    <>
      {itemToUpdate ? (
        <React.Fragment>
          <h5>
            {t("Edit")} {itemToUpdate.title} <IdComponent id={itemToUpdate.id} />
          </h5>
          <h6>{t("Add a custom preview image")}</h6>
          {!editThumbnailMode && (
            <div>
              <div className="mb-3">
                <AppInfoIconComponent pbdModule={PbdModule.None} thumbnailId={itemToUpdate.thumbnailId} />
              </div>
              <Button variant="outline" onClick={toggleEditThumbnailMode} title={t("Change thumbnail picture")}>
                <qmBaseIcons.CameraRetro /> {t("Change image")}
              </Button>
            </div>
          )}
        </React.Fragment>
      ) : (
        <h6>{t("New")}</h6>
      )}

      {itemToUpdate && editThumbnailMode && (
        <CustomCropper
          onSubmit={handleUpload}
          onCancel={toggleEditThumbnailMode}
          refreshParent={toggleEditThumbnailMode}
          baseItem={itemToUpdate}
        />
      )}

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={QuickAccessSchema}>
        {(formikBag) => (
          <FormFormik>
            <Form.Group controlId={"title"} className="mb-3">
              <Form.Label>{t("Title")}</Form.Label>
              <Field name="title" component={FormikTextInput} />
            </Form.Group>
            <Form.Group controlId={"link.href"} className="mb-3">
              <Form.Label>{t("Link")}</Form.Label>
              <Field name="link.href" component={FormikTextInput} />
            </Form.Group>
            <Form.Group controlId={"description"} className="mb-3">
              <Form.Label>{t("Description")}</Form.Label>
              <Field name="description" component={FormikTextInput} type="textarea" rows={3} />
            </Form.Group>
            <Form.Group controlId={"isFavorite"} className="mb-3">
              <Form.Label>{t("Favorite")}</Form.Label>
              <Field
                name={nameof("isFavorite")}
                component={FormikCheckboxInput}
                id="isFavorite"
                label={t("Show this entry in the favorite row on the home page")}
              />
            </Form.Group>

            <CancelSubmitFormGroup onCancel={toggle} formikBag={formikBag} />
            <FormikValidationSummary formikBag={formikBag} />
          </FormFormik>
        )}
      </Formik>
    </>
  );
}

export default EditFormQuickAccess;
