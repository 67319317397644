import React from "react";
import { useTranslation } from "react-i18next";
import useSWRImmutable from "swr/immutable";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { MeAsUser } from "../../../pbdServices/services/UserSettings/models/me-as-user";

export function useApps(meAsUser?: MeAsUser) {
  const { t } = useTranslation();
  const { appsApi } = useAPIs();
  const { settingsService, appService } = useAPIServices();
  const { data, error } = useSWRImmutable(`/api/apps`, () => appService.getAllAsVm(t, meAsUser));
  const { data: appDefinitions } = useSWRImmutable(`/api/apps/appDefintions`, () => appsApi.getAllDefinitions());
  const { data: config } = useSWRImmutable("/billingProps/productConfig", () => settingsService.getProductConfig());

  const brandIcon = React.useMemo(() => {
    const icon = data?.find((x) => x.id == 24);
    if (icon && !icon.href && config) {
      icon.href = config.iconUrl;
    }
    return icon;
  }, [config, data]);

  return {
    available: data?.filter((x) => x.showElement),
    appDefinitions,
    brandIcon,
    notAvailable: data?.filter((x) => !x.showElement && x.type != PbdModule.None),
    all: data,
    isLoading: !error && !data,
    isError: error,
    productConfig: config,
  };
}
