import i18next from "i18next";
import { orderBy, uniq } from "lodash";
import { DateTime } from "luxon";

import {
  CategoryMinDTO,
  CostDTO,
  CostUnit,
  EmployeeIdeaDTO,
  EmployeeIdeaNamedQuery,
  EntityKey,
  ICostDTO,
  IEmployeeIdeaCategoriesControllerClient,
  IEmployeeIdeaDTO,
  IEmployeeIdeaDetailsDTO,
  IEmployeeIdeaReportingDTO,
  IEmployeeIdeaReviewDTO,
  IEmployeeIdeasControllerClient,
  IExternalIdSetting,
  IReportingsControllerClient,
  ITenantContributingEditDTO,
  ITenantsControllerClient,
  PbdModule,
  PbdStatus,
} from "@generatedCode/pbd-core/pbd-core-api";

import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { SortOption } from "../../../ClientApp/shared/components/buttons/sortDropdownButton";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import { BaseProcessingTimeDTO } from "../../Models/BaseClasses/BaseProcessingTimeDTO";
import { AppNotifications } from "../../Models/Notifications/AppNotifications";
import { NotificationItem } from "../../Models/Notifications/ModuleNotification";
import { ValidationResultDescriber } from "../../Models/Shared/validation-result-describer";
import { WithWarnings } from "../../Models/Shared/with-warnings";
import { TenantsMockData } from "../../__tests__/config/mockData/tenantMockData";
import { AuthService } from "../Authz/authService";
import CostService, { WithCosts } from "../Costs/costService";
import ExportService from "../Export/exportService";
import FeatureFlagService from "../FeatureFlags/featureFlagService";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { IEmployeeIdeaReportingVm } from "./models/employeeIdeaReportingVm";
import { IEmployeeIdeaReviewVm } from "./models/employeeIdeaReviewVm";
import { EmployeeIdeaQueryParameters } from "./models/query-parameters";

export default class IdeaManagementService implements IPrerequisitesService {
  employeeIdeasApi: IEmployeeIdeasControllerClient;
  private _reportingsApi: IReportingsControllerClient;
  private _ideaCategoriesApi: IEmployeeIdeaCategoriesControllerClient;
  private _tenantsApi: ITenantsControllerClient;

  constructor(
    employeeIdeasApi: IEmployeeIdeasControllerClient,
    reportingsApi: IReportingsControllerClient,
    ideaCategoriesApi: IEmployeeIdeaCategoriesControllerClient,
    tenantsApi: ITenantsControllerClient,
  ) {
    this.employeeIdeasApi = employeeIdeasApi;
    this._reportingsApi = reportingsApi;
    this._ideaCategoriesApi = ideaCategoriesApi;
    this._tenantsApi = tenantsApi;
  }

  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const categories = await this._ideaCategoriesApi.getAllQuery({ take: 1 });
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "employeeCategory",
        title: "Employee Idea Categories",
        status: categories.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.IdeaManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };

    return resp;
  }

  async getAllForReporting(costs: ICostDTO[]): Promise<WithCosts<IEmployeeIdeaReportingVm>[]> {
    const data = await this._reportingsApi.getEmployeeIdeaReportings();
    const approvers = data.filterMap((x) => x.approvedById);
    const owners = data.filterMap((x) => x.ownerId);
    const uniqueTenantIds = uniq(approvers.concat(owners));
    const categories = await this._ideaCategoriesApi.getAll();
    const tenants = await this._tenantsApi.getAllQuery({
      id: Array.from(uniqueTenantIds),
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    });
    const dataWithCosts = CostService.mapWithCosts(data, costs);
    return dataWithCosts.map((x) => {
      return {
        ...x,
        approvedBy: tenants.data.find((t) => t.id == x.approvedById),
        owner: tenants.data.find((t) => t.id == x.ownerId),
        category: categories.find((c) => c.id == x.categoryId),
      };
    });
  }

  async getAllAsVM(extId: IExternalIdSetting, query?: EmployeeIdeaQueryParameters) {
    const ideas = await this.employeeIdeasApi.getAllQuery(query ?? {});
    return this.mapDTOToVM(ideas, extId);
  }

  mapDTOToVM(items: IEmployeeIdeaDTO[], extId: IExternalIdSetting): WithWarnings<IEmployeeIdeaDTO>[] {
    return items.map((x) => this._createWarningsOnClient(x, extId));
  }

  async getReviewsAsVm(reviews: IEmployeeIdeaReviewDTO[]): Promise<IEmployeeIdeaReviewVm[]> {
    const uniqueIds = uniq(reviews.map((x) => x.reviewerId));
    if (uniqueIds.length == 0) return [];
    const reviewers = await this._tenantsApi.getAllQuery({
      id: uniqueIds,
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    });
    return reviews.map((x) => {
      return {
        ...x,
        reviewer:
          reviewers.data.find((r) => r.id == x.reviewerId) ?? TenantsMockData.getDeletedPlaceholder(x.reviewerId),
      };
    });
  }

  private _createWarningsOnClient(item: IEmployeeIdeaDTO, extId: IExternalIdSetting): WithWarnings<IEmployeeIdeaDTO> {
    const warnings = [];
    if (!item.externalId && extId.isRequired) {
      warnings.push(ValidationResultDescriber.externalIdMissing());
    }
    return {
      ...item,
      warnings,
    };
  }

  async getAllNotificationsAsAppNotification(tenantId: number) {
    const ideasToApproveResp = await this.employeeIdeasApi.getAllQuery({
      approvedById: [tenantId],
      status: [PbdStatus.Submitted],
    });

    const respReviewPending = await this.employeeIdeasApi.getAllQuery({
      namedQuery: EmployeeIdeaNamedQuery.MyPendingReviews,
    });
    const notificationListIdeas: NotificationItem<EmployeeIdeaDTO>[] = [
      new NotificationItem("Ideas to approve", undefined, ideasToApproveResp, "ToApprove"),
      new NotificationItem("Ideas to review", undefined, respReviewPending, "ToReview"),
    ];

    const respNotCompleted = await this.employeeIdeasApi.getAllQuery({
      status: [PbdStatus.Approved, PbdStatus.UnderReview, PbdStatus.InProgress],
      ownerId: [tenantId],
    });
    notificationListIdeas.push(new NotificationItem("Ideas as manager", undefined, respNotCompleted, "AsIdeaManager"));

    return new AppNotifications(PbdModule.IdeaManagement, notificationListIdeas);
  }

  static hasActiveReview(dto: IEmployeeIdeaDTO, tenantId: number) {
    if (dto.status != PbdStatus.UnderReview) return undefined;
    const respReviewActive = dto.reviews?.find((f) => f.reviewer?.id == tenantId && f.status == PbdStatus.Pending);
    return respReviewActive;
  }

  canChangeReviewVisibility(authService: AuthService, item: IEmployeeIdeaDetailsDTO, review: IEmployeeIdeaReviewDTO) {
    if (
      item.status == PbdStatus.UnderReview &&
      review.answeredAt &&
      (item.owner?.id == authService.tenantId || authService.hasRole([PbdRoles.Admin]))
    ) {
      return true;
    }
    return false;
  }

  getVisibleReviews(
    item: IEmployeeIdeaDetailsDTO,
    reviewsMapped: IEmployeeIdeaReviewVm[],
    meAsUser: MeAsUser,
    sortingDirection: SortOption<IEmployeeIdeaReviewVm>,
  ): IEmployeeIdeaReviewVm[] | undefined {
    if (!item.reviews) return undefined;
    const visibleItems = item.reviews.filter((x) => this.canSeeReview(x, item, meAsUser));
    return orderBy(
      reviewsMapped.filter((x) => visibleItems.map((v) => v.reviewerId).includes(x.reviewerId)),
      sortingDirection.id,
      sortingDirection.direction,
    );
  }

  canSeeReview(review: IEmployeeIdeaReviewDTO, idea: IEmployeeIdeaDetailsDTO, meAsUser: MeAsUser) {
    if (review.reviewerId == meAsUser.tenant.id) {
      return true;
    }
    if (idea.owner?.id == meAsUser.tenant.id) {
      return true;
    }
    if (hasRole(meAsUser, [PbdRoles.IdeaManagement_Manager, PbdRoles.IdeaManagement_ModuleAdmin])) {
      return true;
    }
    if (review.isPublished) {
      return true;
    }
    return false;
  }

  mapLegacyCosts(item: IEmployeeIdeaDetailsDTO) {
    const category = new CategoryMinDTO({
      id: 0,
      title: i18next.t("Transfer from legacy costs"),
      entityKey: EntityKey.EmployeeIdeaCategory,
    });
    const costCenter = new CategoryMinDTO({
      id: -1,
      title: "-",
      entityKey: EntityKey.CostCenter,
    });
    const costs: ICostDTO[] = [];
    if (item.realizedSavings) {
      costs.push(
        new CostDTO({
          id: 0,
          createdAt: DateTime.fromISO("0001-01-01"),
          category,
          costCenter,
          value: item.realizedSavings,
          title: i18next.t("Realized savings"),
          costUnit: CostUnit.Money,
          currency: "EUR",
          keyName: "fromLegacy",
          keyValues: "fromLegacy",
          createdById: 1,
        }),
      );
    }
    if (item.estimatedSavings) {
      costs.push(
        new CostDTO({
          id: -1,
          createdAt: DateTime.fromISO("0001-01-01"),
          category,
          costCenter,
          value: item.estimatedSavings,
          title: i18next.t("Estimated savings"),
          costUnit: CostUnit.Money,
          currency: "EUR",
          keyName: "fromLegacy",
          keyValues: "fromLegacy",
          createdById: 1,
        }),
      );
    }
    if (item.awardedMoney) {
      costs.push(
        new CostDTO({
          id: -2,
          createdAt: DateTime.fromISO("0001-01-01"),
          category,
          costCenter,
          value: item.awardedMoney,
          title: i18next.t("Awarded money"),
          costUnit: CostUnit.Money,
          currency: "EUR",
          keyName: "fromLegacy",
          keyValues: "fromLegacy",
          createdById: 1,
        }),
      );
    }

    return costs;
  }

  calculateProcessingTime(
    items: IEmployeeIdeaReportingDTO[],
    query: { createdFrom: DateTime; createdTo: DateTime },
  ): BaseProcessingTimeDTO[] {
    const result: BaseProcessingTimeDTO[] = [];
    const toSubmit = items
      .filter((x) => x.submittedAt && x.submittedAt > query.createdFrom && x.submittedAt < query.createdTo)
      .map((x) => {
        return { start: x.createdAt, end: x.submittedAt! };
      });
    const toSubmitDuration = DateTimeLuxonHelpers.getProcessingTime(toSubmit);
    // result.push(new BaseProcessingTimeDTO(PbdStatus.Open, i18next.t("Open"), "", Duration.fromMillis(0), []));
    result.push(
      new BaseProcessingTimeDTO(
        PbdStatus.Submitted,
        "Time to submit",
        `Σ(${i18next.t("Submitted")}-${i18next.t("Created")})`,
        toSubmitDuration,
        toSubmit,
      ),
    );

    const toApprove = items
      .filter(
        (x) => x.approvedAt && x.submittedAt && x.approvedAt > query.createdFrom && x.approvedAt < query.createdTo,
      )
      .map((x) => {
        return { start: x.submittedAt!, end: x.approvedAt! };
      });
    const toApproveDuration = DateTimeLuxonHelpers.getProcessingTime(toApprove);
    result.push(
      new BaseProcessingTimeDTO(
        PbdStatus.Approved,
        "Time to approve or reject",
        `Σ(${i18next.t("Approved")}-${i18next.t("Submitted")})`,
        toApproveDuration,
        toApprove,
      ),
    );

    const toComplete = items
      .filter(
        (x) => x.completedAt && x.approvedAt && x.completedAt > query.createdFrom && x.completedAt < query.createdTo,
      )
      .map((x) => {
        return { start: x.approvedAt!, end: x.completedAt! };
      });
    const toCompleteDuration = DateTimeLuxonHelpers.getProcessingTime(toComplete);
    result.push(
      new BaseProcessingTimeDTO(
        PbdStatus.Completed,
        "Time to complete",
        `Σ(${i18next.t("Completed")}-${i18next.t("Approved")})`,
        toCompleteDuration,
        toComplete,
      ),
    );
    if (FeatureFlagService.isMubea(window.location.host)) {
      const toRegistered = items
        .filter(
          (x) =>
            x.completedAt && x.registeredAt && x.completedAt > query.createdFrom && x.completedAt < query.createdTo,
        )
        .map((x) => {
          return { start: x.registeredAt!, end: x.completedAt! };
        });
      const toRegisteredDuration = DateTimeLuxonHelpers.getProcessingTime(toRegistered);
      result.push(
        new BaseProcessingTimeDTO(
          PbdStatus.Completed,
          "Time to complete",
          `Σ(${i18next.t("Completed")}-${i18next.t("Registered")})`,
          toRegisteredDuration,
          toRegistered,
        ),
      );
    }

    return result;
  }

  static calculateTotalContribution(data: ITenantContributingEditDTO[]) {
    return data.reduce((pv, cv) => pv + cv.partOfContribution, 0);
  }

  static showExtendedWorkflow(dto: IEmployeeIdeaDetailsDTO, viewType: string) {
    if (viewType != "Expert") return false;
    const status = [PbdStatus.Open, PbdStatus.Submitted, PbdStatus.ReturnedToSender];
    if (status.includes(dto.status)) {
      return false;
    } else {
      return true;
    }
  }

  static exportToCsv(items: IEmployeeIdeaDTO[]) {
    return ExportService.exportCSV("Ideas", items, (x) => ({
      id: x.id,
      title: x.title,
      tags: x.tags?.map((t) => t.title).join(),
      category: x.category?.title,
      status: x.status,
      createdBy: x.createdBy?.fullName,
      approvedBy: x.approvedBy?.fullName,
      owner: x.owner?.fullName,
      contributors: x.contributors?.map((c) => c.fullName).join(),
      reviews: x.reviews?.map((c) => c.reviewer?.fullName).join(),
      estimatedSavings: x.estimatedSavings,
      realizedSavings: x.realizedSavings,
      createdAt: x.createdAt,
      lastUpdatedAt: x.lastUpdatedAt,
      ideaImplementedAt: x.ideaImplementedAt,
    }));
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Category,
      SearchFilterTypes.CreatedBy,
      SearchFilterTypes.ReviewerId,
      SearchFilterTypes.Owner,
      SearchFilterTypes.ApprovedById,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.LastUpdatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.ContributorId,
    ];
  }

  static isLegacyApproval(dto: IEmployeeIdeaDTO) {
    if (!dto.submittedAt || !dto.approvedAt) return false;
    return dto.submittedAt > dto.approvedAt;
  }

  static showApprovalState(dto: IEmployeeIdeaDTO) {
    if (!dto.submittedAt) return false;
    if (!dto.approvedAt) return false;
    if (dto.submittedAt > dto.approvedAt) return false;
    return true;
  }

  static showImplementationCardBody(dto: IEmployeeIdeaDetailsDTO): boolean {
    return (
      dto.status == PbdStatus.InProgress ||
      dto.progressValue != undefined ||
      dto.progressComment != undefined ||
      dto.completedAt != undefined
    );
  }

  static showApprovalCard(dto: IEmployeeIdeaDetailsDTO): boolean {
    return dto.approvedBy != undefined && dto.status != PbdStatus.Open;
  }

  static showReviewAndImplementation(dto: IEmployeeIdeaDetailsDTO): boolean {
    return (
      dto.status != PbdStatus.Open && dto.status != PbdStatus.Submitted && dto.status != PbdStatus.ReturnedToSender
    );
  }

  static isApprovalCardActive(idea: IEmployeeIdeaDetailsDTO): boolean {
    return (
      idea.status == PbdStatus.Submitted || idea.status == PbdStatus.Approved || idea.status == PbdStatus.NotApproved
    );
  }

  static get entityFieldKeys() {
    return ["approvalComment", "progressComment", "reviewResultComment"];
  }

  static get ideaDefaultWorkflow() {
    return [PbdStatus.Open, PbdStatus.Submitted, PbdStatus.Accepted, PbdStatus.UnderReview, PbdStatus.Completed];
  }
}
