import { TFunction } from "i18next";

import {
  EntityKey,
  IAbsenceRequestsControllerClient,
  IArticlesControllerClient,
  IAuditsControllerClient,
  IClaimsControllerClient,
  ICompanyFunctionsControllerClient,
  IConnectionsControllerClient,
  ICustomFormAnswersControllerClient,
  ICustomFormsControllerClient,
  IDefectsControllerClient,
  IDepartmentPositionsControllerClient,
  IEmployeeIdeasControllerClient,
  IGoalsControllerClient,
  IInventoryItemsControllerClient,
  ILinkedResource,
  ILinkedResourceMinDTO,
  IOpportunitiesControllerClient,
  IOrganisationsControllerClient,
  IProductsControllerClient,
  IQualificationsControllerClient,
  ITenantsControllerClient,
  IToDosControllerClient,
  ITrainingTypesControllerClient,
  ITrainingsControllerClient,
  IUsersControllerClient,
} from "@generatedCode/pbd-core/pbd-core-api";
import { ControllerContextData } from "@generatedCode/pbd-core/pbd-core-api-services";

import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { IConnectedInput, IConnectedItem, LinkedResourceDetails, LinkedResourceVm } from "./models/connectedItem";

export default class ConnectionService {
  private _connectionsApi: IConnectionsControllerClient;
  private _customFormsApi: ICustomFormsControllerClient;
  private _customFormAnswersApi: ICustomFormAnswersControllerClient;
  private _qualificationsApi: IQualificationsControllerClient;
  private _companyFunctionsApi: ICompanyFunctionsControllerClient;
  private _trainingTypesApi: ITrainingTypesControllerClient;
  private _articlesApi: IArticlesControllerClient;
  private _auditsApi: IAuditsControllerClient;
  private _defectsApi: IDefectsControllerClient;
  private _departmentPositionsApi: IDepartmentPositionsControllerClient;
  private _goalsApi: IGoalsControllerClient;
  private _opportunitiesApi: IOpportunitiesControllerClient;
  private _trainingsApi: ITrainingsControllerClient;
  private _todosApi: IToDosControllerClient;
  private _absenceRequestsApi: IAbsenceRequestsControllerClient;
  private _applicationUsersApi: IUsersControllerClient;
  private _claimsApi: IClaimsControllerClient;
  private _employeeIdeasApi: IEmployeeIdeasControllerClient;
  private _inventoryItemsApi: IInventoryItemsControllerClient;
  private _organisationsApi: IOrganisationsControllerClient;
  private _productsApi: IProductsControllerClient;
  private _tenantsApi: ITenantsControllerClient;

  constructor(apis: ControllerContextData) {
    this._connectionsApi = apis.connectionsApi;
    this._customFormsApi = apis.customFormsApi;
    this._customFormAnswersApi = apis.customFormAnswersApi;
    this._qualificationsApi = apis.qualificationsApi;
    this._companyFunctionsApi = apis.companyFunctionsApi;
    this._trainingTypesApi = apis.trainingTypesApi;
    this._articlesApi = apis.articlesApi;
    this._auditsApi = apis.auditsApi;
    this._defectsApi = apis.defectsApi;
    this._departmentPositionsApi = apis.departmentPositionsApi;
    this._goalsApi = apis.goalsApi;
    this._opportunitiesApi = apis.opportunitiesApi;
    this._trainingsApi = apis.trainingsApi;
    this._todosApi = apis.toDosApi;
    this._absenceRequestsApi = apis.absenceRequestsApi;
    this._applicationUsersApi = apis.usersApi;
    this._claimsApi = apis.claimsApi;
    this._employeeIdeasApi = apis.employeeIdeasApi;
    this._inventoryItemsApi = apis.inventoryItemsApi;
    this._organisationsApi = apis.organisationsApi;
    this._productsApi = apis.productsApi;
    this._tenantsApi = apis.tenantsApi;
  }

  async getConnectionsFromDynamicPropFromLinkedResources(
    item: {
      id: number;
      linkedResources?: ILinkedResource[];
      entityKey: EntityKey;
    },
    entityToQuery: EntityKey,
  ) {
    if (!item.linkedResources) return {};
    const customFormIds = item.linkedResources
      .filter((x) => x.entityKey == EntityKey.CustomForm)
      .map((x) => Number(x.id));
    const qualificationIds = item.linkedResources
      .filter((x) => x.entityKey == EntityKey.Qualification)
      .map((x) => Number(x.id));
    const customForms =
      customFormIds.length > 0 && EntityKey.CustomForm
        ? await this._customFormsApi.getAllQuery({ id: customFormIds })
        : [];
    const qualifications =
      qualificationIds.length > 0 && entityToQuery == EntityKey.Qualification
        ? await this._qualificationsApi.getAllQuery({ id: qualificationIds })
        : [];
    return { customForms, qualifications };
  }

  async getLinkedResourcesEnriched(linkedResourcesMin: ILinkedResourceMinDTO[]) {
    const connectedIds = this.getConnectedIds(linkedResourcesMin);
    const result: LinkedResourceVm[] = [];
    if (connectedIds.absenceRequestIds.length > 0) {
      (await this._absenceRequestsApi.getAllQuery({ id: connectedIds.absenceRequestIds })).map((x) =>
        result.push(new LinkedResourceVm({ ...x, id: x.id })),
      );
    }
    if (connectedIds.applicationUserIds.length > 0) {
      (await this._applicationUsersApi.getAllQuery({ id: connectedIds.applicationUserIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.claimIds.length > 0) {
      (await this._claimsApi.getAllQuery({ id: connectedIds.claimIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.employeeIdeaIds.length > 0) {
      (await this._employeeIdeasApi.getAllQuery({ id: connectedIds.employeeIdeaIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.inventoryItemIds.length > 0) {
      (await this._inventoryItemsApi.getAllQuery({ id: connectedIds.inventoryItemIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.organisationIds.length > 0) {
      (await this._organisationsApi.getAllQuery({ id: connectedIds.organisationIds })).data.map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.productIds.length > 0) {
      (await this._productsApi.getAllQuery({ id: connectedIds.productIds })).data.map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.articleIds.length > 0) {
      (await this._articlesApi.getAllQuery({ id: connectedIds.articleIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.auditIds.length > 0) {
      (await this._auditsApi.getAllQuery({ id: connectedIds.auditIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.companyFunctionIds.length > 0) {
      (await this._companyFunctionsApi.getAllQuery({ id: connectedIds.companyFunctionIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.customFormIds.length > 0) {
      (await this._customFormsApi.getAllQuery({ id: connectedIds.customFormIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.customFormAnswerIds.length > 0) {
      (await this._customFormAnswersApi.getAllQuery({ id: connectedIds.customFormAnswerIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.defectIds.length > 0) {
      (await this._defectsApi.getAllQuery({ id: connectedIds.defectIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.departmentPositionIds.length > 0) {
      (await this._departmentPositionsApi.getAllQuery({ id: connectedIds.departmentPositionIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.goalIds.length > 0) {
      (await this._goalsApi.getAllQuery({ id: connectedIds.goalIds })).map((x) => result.push(new LinkedResourceVm(x)));
    }
    if (connectedIds.opportunityIds.length > 0) {
      (await this._opportunitiesApi.getAllQuery({ id: connectedIds.opportunityIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.trainingIds.length > 0) {
      (await this._trainingsApi.getAllQuery({ id: connectedIds.trainingIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.todoIds.length > 0) {
      (await this._todosApi.getAllQuery({ id: connectedIds.todoIds })).map((x) => result.push(new LinkedResourceVm(x)));
    }
    if (connectedIds.qualificationIds.length > 0) {
      (await this._qualificationsApi.getAllQuery({ id: connectedIds.qualificationIds })).map((x) =>
        result.push(new LinkedResourceVm(x)),
      );
    }
    if (connectedIds.tenantIds.length > 0) {
      (
        await this._tenantsApi.getAllQuery({
          id: connectedIds.tenantIds,
          pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        })
      ).data.map((x) => result.push(new LinkedResourceVm(x)));
    }
    return result;
  }

  async getLinkedResourceById(entityKey: EntityKey, id: string): Promise<null | LinkedResourceDetails> {
    switch (entityKey) {
      case EntityKey.Article:
        return this._articlesApi.getById(id).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.Audit:
        return this._auditsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.AbsenceRequest:
        return this._absenceRequestsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.ApplicationUser:
        return this._applicationUsersApi.getById(id).then((x) => new LinkedResourceDetails(x, entityKey));
      case EntityKey.CustomFormAnswer:
        return this._customFormAnswersApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.CompanyFunction:
        return this._companyFunctionsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.Defect:
        return this._defectsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.Goal:
        return this._goalsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.Qualification:
        return this._qualificationsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.Claim:
        return this._claimsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.EmployeeIdea:
        return this._employeeIdeasApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.InventoryItem:
        return this._inventoryItemsApi
          .getById(Number(id))
          .then((x) => new LinkedResourceDetails(x, EntityKey.InventoryItem));
      case EntityKey.Organisation:
        return this._organisationsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, x.entityKey));
      case EntityKey.Product:
        return this._productsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, EntityKey.Product));
      case EntityKey.Tenant:
        return this._tenantsApi.getById(Number(id)).then((x) => new LinkedResourceDetails(x, EntityKey.Tenant));
      default:
        return null;
    }
  }

  getConnectedIds(items: ILinkedResourceMinDTO[]) {
    const articleIds = items.filter((x) => x.entity == EntityKey.Article).map((x) => Number(x.id));
    const auditIds = items.filter((x) => x.entity == EntityKey.Audit).map((x) => Number(x.id));
    const customFormIds = items.filter((x) => x.entity == EntityKey.CustomForm).map((x) => Number(x.id));
    const customFormAnswerIds = items.filter((x) => x.entity == EntityKey.CustomFormAnswer).map((x) => Number(x.id));
    const defectIds = items.filter((x) => x.entity == EntityKey.Defect).map((x) => Number(x.id));
    const departmentPositionIds = items
      .filter((x) => x.entity == EntityKey.DepartmentPosition)
      .map((x) => Number(x.id));
    const goalIds = items.filter((x) => x.entity == EntityKey.Goal).map((x) => Number(x.id));
    const opportunityIds = items.filter((x) => x.entity == EntityKey.Opportunity).map((x) => Number(x.id));
    const todoIds = items.filter((x) => x.entity == EntityKey.ToDo).map((x) => Number(x.id));
    const trainingIds = items.filter((x) => x.entity == EntityKey.Training).map((x) => Number(x.id));
    const companyFunctionIds = items.filter((x) => x.entity == EntityKey.CompanyFunction).map((x) => Number(x.id));
    const qualificationIds = items.filter((x) => x.entity == EntityKey.Qualification).map((x) => Number(x.id));
    const absenceRequestIds = items.filter((x) => x.entity == EntityKey.AbsenceRequest).map((x) => Number(x.id));
    const applicationUserIds = items.filter((x) => x.entity == EntityKey.ApplicationUser).map((x) => x.id);
    const claimIds = items.filter((x) => x.entity == EntityKey.Claim).map((x) => Number(x.id));
    const employeeIdeaIds = items.filter((x) => x.entity == EntityKey.EmployeeIdea).map((x) => Number(x.id));
    const inventoryItemIds = items.filter((x) => x.entity == EntityKey.InventoryItem).map((x) => Number(x.id));
    const organisationIds = items.filter((x) => x.entity == EntityKey.Organisation).map((x) => Number(x.id));
    const productIds = items.filter((x) => x.entity == EntityKey.Product).map((x) => Number(x.id));
    const tenantIds = items.filter((x) => x.entity == EntityKey.Tenant).map((x) => Number(x.id));

    return {
      articleIds,
      auditIds,
      customFormIds,
      customFormAnswerIds,
      defectIds,
      departmentPositionIds,
      goalIds,
      opportunityIds,
      todoIds,
      trainingIds,
      companyFunctionIds,
      qualificationIds,
      absenceRequestIds,
      applicationUserIds,
      claimIds,
      employeeIdeaIds,
      inventoryItemIds,
      organisationIds,
      productIds,
      tenantIds,
    };
  }

  static getSWRKey(connections: ILinkedResource[] | undefined, entityKey: EntityKey) {
    if (!connections || connections.length == 0) return undefined;
    const ids = this.getIdsByKeyAsNumber(connections, entityKey);
    return JSON.stringify({ key: entityKey, ids: ids?.map((x) => Number(x)) });
  }

  static getIdsByKeyAsNumber(connections: ILinkedResource[] | undefined, entityKey: EntityKey) {
    if (!connections || connections.length == 0) return undefined;
    const ids = this.getIdsByKey(connections, entityKey);
    return ids?.map((x) => Number(x));
  }

  static getIdsByKeyAsNumberNew(
    connections: ILinkedResourceMinDTO[] | undefined,
    entityKey: EntityKey,
  ): number[] | undefined {
    if (!connections || connections.length == 0) return undefined;
    const data = connections.filter((x) => x.entity == entityKey);
    if (data.length == 0) return undefined;

    return data.map((x) => Number(x.id));
  }

  /**This will return an empty array if no suitable connections are found */
  static mustGetIdsByKeyAsNumber(
    connections: ILinkedResource[] | undefined,
    entityKey: EntityKey,
  ): number[] | undefined {
    if (!connections) return undefined;
    const ids = this.getIdsByKey(connections, entityKey);
    return ids?.map((x) => Number(x)) ?? [];
  }

  static getIdsByKey(connections: ILinkedResource[] | undefined, entityKey: EntityKey) {
    if (!connections) return undefined;
    return connections.filter((x) => x.entityKey == entityKey).map((x) => x.id);
  }

  async getRequiredCustomFormsAndAnswers(customFormIds: number[] | undefined, tenantId: number) {
    if (!customFormIds) return undefined;
    const forms = await this._customFormsApi.getAllQuery({ id: customFormIds });
    const answers =
      customFormIds.length > 0
        ? await this._customFormAnswersApi.getAllQuery({ customFormId: customFormIds, createdById: [tenantId] })
        : [];
    return {
      forms: forms.map((x) => {
        return { ...x, filledOutForms: answers.filter((f) => f.customFormId == x.id) };
      }),
      answers,
    };
  }

  getConnectedItems({ tasks, trainings, claims }: IConnectedInput) {
    const resp: IConnectedItem[] = [];
    if (tasks) {
      resp.push(...tasks);
    }
    if (trainings) {
      resp.push(...trainings);
    }
    if (claims) {
      resp.push(...claims);
    }
    return resp;
  }

  static getSWRKeyGlobal(prefix: string, linkedResources: ILinkedResource[] | undefined) {
    if (!linkedResources) return undefined;
    return [prefix, linkedResources];
  }

  static getTranslatedConnections(
    items: AvailableConnection[],
    t: TFunction,
  ): (AvailableConnection & { displayName: string })[] {
    const mapped: (AvailableConnection & { displayName: string })[] = items.map((x) => {
      return { ...x, displayName: t(`entityKeys::${x.entityKey}`) };
    });
    return mapped.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  static get implementedConnections() {
    return [
      EntityKey.AbsenceRequest,
      EntityKey.ApplicationUser,
      EntityKey.Article,
      EntityKey.Audit,
      EntityKey.Claim,
      EntityKey.CompanyFunction,
      EntityKey.CustomForm,
      EntityKey.CustomFormAnswer,
      EntityKey.Defect,
      EntityKey.DepartmentPosition,
      EntityKey.EmployeeIdea,
      EntityKey.Goal,
      EntityKey.InventoryItem,
      EntityKey.Opportunity,
      EntityKey.Organisation,
      EntityKey.Product,
      EntityKey.Qualification,
      EntityKey.ToDo,
      EntityKey.Training,
      EntityKey.Tenant,
    ];
  }
}
