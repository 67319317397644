import React from "react";
import { Button, Card, Col, Collapse, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DecodedValueMap, SetQuery, useQueryParams } from "use-query-params";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import AddFilterComponent from "./addFilterComponent";
import { FilterElement, SearchFilterTypes } from "./availableSearchFilters";
import { DefaultQueryParamsConfig, QueryParamsConfigPbd } from "./queryParamsConfigPbd";
import RenderSearchFilterLegacy from "./renderSearchFilterLegacy";
import { useLocalQueryParams } from "./useLocalQueryParams";
import { useSelectedFilterHook } from "./useSelectedFilterHook";

interface IProps {
  availableFilters?: FilterElement[];
  collapsed: boolean;
  onFilterChanged?: () => void;

  /**This parameter needs to be passed to retrieve the custom fields related with the module. */
  pbdModule?: PbdModule;
  searchFilterCustomization?: React.ReactNode;
  query: DecodedValueMap<typeof QueryParamsConfigPbd>;
  setQuery: SetQuery<typeof QueryParamsConfigPbd>;
  onClearFilter?: () => void;
}

export function GenericBaseExtendedSearchFilter(props: IProps) {
  const {
    availableFilters,
    collapsed,
    pbdModule,
    searchFilterCustomization,
    query,
    setQuery,
    onClearFilter,
    onFilterChanged,
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [tempFilters, setTempFilters] = React.useState<SearchFilterTypes[]>([]);

  const clearAllFilters = () => {
    if (onClearFilter) {
      onClearFilter();
    } else {
      setQuery({}, "replace");
    }
    setTempFilters([]);
  };

  const { selectedFiltersState } = useSelectedFilterHook(query);

  const handleOnNewFilterChange = (filter: SearchFilterTypes) => setTempFilters([...tempFilters, filter]);

  if (!availableFilters) return null;

  return (
    <Collapse in={collapsed}>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            {selectedFiltersState.map((f) => {
              const selectedFilter = availableFilters.find((x) => x.type == f.type);
              if (selectedFilter) {
                return (
                  <Col key={f.type} md={3}>
                    <RenderSearchFilterLegacy
                      type={selectedFilter}
                      pbdModule={pbdModule}
                      query={query}
                      setQuery={setQuery}
                      onFilterChanged={onFilterChanged}
                    />
                  </Col>
                );
              } else {
                console.log("filter not available", selectedFilter, f);
                return null;
              }
            })}

            {tempFilters
              .filter((x) => !selectedFiltersState.map((y) => y.type).includes(x))
              .map((f, i) => (
                <Col key={i} md={3}>
                  <RenderSearchFilterLegacy
                    type={availableFilters.find((x) => x.type == f)!}
                    pbdModule={pbdModule}
                    query={query}
                    setQuery={setQuery}
                    onFilterChanged={onFilterChanged}
                  />
                </Col>
              ))}
            <Col md={3}>
              <Form.Group controlId="addFilterId" className="mb-3">
                <Form.Label>
                  <qmBaseIcons.Plus /> {t("Add new filter")}
                </Form.Label>
                <AddFilterComponent
                  onSelect={handleOnNewFilterChange}
                  data={availableFilters}
                  selected={selectedFiltersState}
                  inputId="addFilterId"
                />
              </Form.Group>
            </Col>
          </Row>

          {searchFilterCustomization}
          <Row>
            {location.search && (
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Button variant="outline-danger" size="sm" onClick={clearAllFilters}>
                    {t("Clear all filters")}
                  </Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Collapse>
  );
}

export const GenericExtendedSearchFilter = (props: Omit<IProps, "setQuery" | "query">) => {
  const [query, setQuery] = useQueryParams(QueryParamsConfigPbd);
  return <GenericBaseExtendedSearchFilter {...props} query={query} setQuery={setQuery} />;
};

export const GenericExtendedLocalSearchFilter = (props: Omit<IProps, "setQuery" | "query">) => {
  const [query, setQuery] = useLocalQueryParams(QueryParamsConfigPbd, DefaultQueryParamsConfig);
  return <GenericBaseExtendedSearchFilter {...props} query={query} setQuery={setQuery} />;
};

export default GenericExtendedSearchFilter;
