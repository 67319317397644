import { Field, FieldArray, Form, Formik } from "formik";
import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup";

import { BankHolidaySettings, Holidays, IBankHolidaySettings, ISetting } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import CancelButton from "../../../shared/components/buttons/cancelButton";
import { FormikCheckboxInput } from "../../../shared/components/forms/formik/formikCheckboxInput";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import { FormikLuxonDateInput } from "../../../shared/components/forms/formik/formikLuxonDateInput";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";
import DateRangeFilter from "../../../shared/components/genericSearchFilter/dateRangeFilter";

type LocalDateType = Pick<IBankHolidaySettings, "from" | "to">;
//@ts-expect-error TODO: Fix typings
const datePart: yup.ObjectSchema<LocalDateType> = yup.object({
  from: yup.date().required(),
  to: yup.date().required(),
});

//@ts-expect-error TODO: Fix with better typings
const ValidationSchema: yup.ObjectSchema<IBankHolidaySettings> = yup
  .object({
    id: yup.string().required().min(2).max(250),
    title: yup.string().required().min(2).max(250),
    holidays: yup.array().of(yup.object<any>()),
    year: yup.number().required(),
  })
  .concat(datePart);

const nameof = nameofFactory<IBankHolidaySettings>();

interface IProps {
  itemToUpdate?: IBankHolidaySettings;
  onSubmit: (dto: BankHolidaySettings) => Promise<ISetting>;
  onSuccess: () => void;
  onCancel: () => void;
}

const BankHolidayForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { itemToUpdate, onCancel, onSubmit, onSuccess } = props;

  const submitter = useFormikAPISubmitter<IBankHolidaySettings, ISetting>(
    (val) => onSubmit(new BankHolidaySettings(val)),
    [onSubmit],
    onSuccess,
  );

  const initialValues: IBankHolidaySettings = {
    id: itemToUpdate?.id ?? nanoid(),
    title: itemToUpdate?.title ?? "BankHolidays",
    from: itemToUpdate?.from ?? DateTime.now().startOf("year"),
    to: itemToUpdate?.to ?? DateTime.now().plus({ years: 20 }).endOf("year"),
    year: itemToUpdate?.year ?? 0,
    holidays: itemToUpdate?.holidays ?? [
      new Holidays({
        id: nanoid(),
        title: t("New year"),
        date: DateTime.now().startOf("year"),
        isRecurring: true,
      }),
    ],
  };

  return (
    <React.Fragment>
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
        {(formikBag) => (
          <Form>
            <FormikDebugInfo formikBag={formikBag} />

            <FormGroup>
              <Label for={nameof("title")}>{t("Name")}</Label>
              <Field name={nameof("title")} component={FormikTextInput} readOnly />
            </FormGroup>
            <DateRangeFilter
              label={t("Period")}
              from={formikBag.values.from}
              setFrom={(x) => formikBag.setFieldValue(nameof("from"), x)}
              to={formikBag.values.to}
              setTo={(x) => formikBag.setFieldValue(nameof("to"), x)}
              readOnly
            />
            <h3>{t("List of defined bank holidays")}</h3>
            <FieldArray name={nameof("holidays")}>
              {({ insert, remove, push }) => (
                <div className="mb-3">
                  {formikBag.values.holidays.length > 0 &&
                    formikBag.values.holidays.map((holiday, index) => (
                      <Row key={index}>
                        <Col md={5}>
                          <FormGroup>
                            <Label htmlFor={`${nameof("holidays")}.${index}.title`}>{t("Title")}</Label>
                            <Field name={`${nameof("holidays")}.${index}.title`} component={FormikTextInput} />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label htmlFor={`${nameof("holidays")}.${index}.date`}>{t("Date")}</Label>
                            <Field name={`${nameof("holidays")}.${index}.date`} component={FormikLuxonDateInput} />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label htmlFor={`${nameof("holidays")}.${index}.isRecurring`}>{t("Recurring")}</Label>
                            <Field
                              name={`${nameof("holidays")}.${index}.isRecurring`}
                              id={`${nameof("holidays")}.${index}.id`}
                              component={FormikCheckboxInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label>&nbsp;</Label>
                            <Button color="danger" outline onClick={() => remove(index)} size="sm">
                              {t("Remove")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    ))}
                  <Button
                    onClick={() =>
                      push(new Holidays({ id: nanoid(), title: "", date: DateTime.now(), isRecurring: false }))
                    }
                    size="sm"
                  >
                    {t("Add")}
                  </Button>
                </div>
              )}
            </FieldArray>
            <FormGroup>
              <CancelButton onClick={onCancel} />
              <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                {t("Save")}
              </Button>
            </FormGroup>
            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default BankHolidayForm;
