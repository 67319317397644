import { uniq, uniqBy } from "lodash";

import {
  BaseMinDTO,
  IAuditsControllerClient,
  ICustomFormAnswerDTO,
  ICustomFormAnswersControllerClient,
  ICustomFormDTO,
  ICustomFormLinksControllerClient,
  ICustomFormsControllerClient,
  IQualificationDTO,
  IRatingFieldDTO,
  ITenantDTO,
  ITenantQualificationDTO,
  ITrainingsControllerClient,
  PbdModule,
} from "@generatedCode/pbd-core/pbd-core-api";

import { AuditRoutePaths } from "../../../ClientApp/audits/auditRoutePaths";
import { IHaveTags } from "../../../ClientApp/settings/tags/components/tagList";
import { TrainingRoutePaths } from "../../../ClientApp/trainings/trainingRoutePaths";
import { CustomFormAnswerSource } from "../../../Models/CustomForms/CustomFormAnswerSource";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import CustomFormConnectionService from "../../../services/CustomForms/customFormConnectionService";
import CustomFormService from "../../../services/CustomForms/customFormService";
import { ValidationResult } from "../../Models/Shared/validation-result";
import { CustomFormMockData } from "../../__tests__/config/mockData/customFormMockData";
import ExportService, { ExportType } from "../Export/exportService";
import JsonHelpers from "../Json/jsonHelpers";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { ICustomFormAnswerVm } from "./models/custom-form-answer-vm";
import { ICustomFormAnswerPreparationVm } from "./models/customFormAnswerPreparationVm";
import { CustomFormAnswerQueryParameters } from "./models/query-parameters";

interface CustomFormAnswerSourceItem {
  customFormLinkId: number;
  objectName: "Training" | "Audit";
  objectId: number;
  navigationToSourceUrl: string;
  object: BaseMinDTO;
  numberOfTimes: number;
}

export default class CustomFormAnswerService {
  customFormAnswersControllerClient: ICustomFormAnswersControllerClient;
  customFormsControllerClient: ICustomFormsControllerClient;
  customFormLinksControllerClient: ICustomFormLinksControllerClient;
  trainingControllerClient: ITrainingsControllerClient;
  auditControllerClient: IAuditsControllerClient;
  constructor(
    customFormAnswersControllerClient: ICustomFormAnswersControllerClient,
    customFormsControllerClient: ICustomFormsControllerClient,
    customFormLinksControllerClient: ICustomFormLinksControllerClient,
    trainingControllerClient: ITrainingsControllerClient,
    auditControllerClient: IAuditsControllerClient,
  ) {
    this.customFormAnswersControllerClient = customFormAnswersControllerClient;
    this.customFormsControllerClient = customFormsControllerClient;
    this.customFormLinksControllerClient = customFormLinksControllerClient;
    this.trainingControllerClient = trainingControllerClient;
    this.auditControllerClient = auditControllerClient;
  }

  /**This query returns the select answers and maps the forms to it */
  async getAllQuery(query: CustomFormAnswerQueryParameters): Promise<ICustomFormAnswerVm[]> {
    const resp = await this.customFormAnswersControllerClient.getAllQuery(query);
    return this.mapFormToAnswers(resp);
  }

  async getById(id: number): Promise<ICustomFormAnswerVm> {
    const resp = await this.customFormAnswersControllerClient.getById(id);
    return this.mapFormToAnswer(resp);
  }

  async mapFormToAnswers(items: ICustomFormAnswerDTO[]) {
    const uniqueCustomFormIds = uniq(items.map((x) => x.customFormId));
    const forms = await this.customFormsControllerClient.getAllQuery({ id: uniqueCustomFormIds });
    const mapped: ICustomFormAnswerVm[] = items.map((x) => {
      return {
        ...x,
        customForm:
          forms.find((f) => f.id == x.customFormId) ?? CustomFormMockData.deletedFormPlaceholder(x.customFormId),
      };
    });
    return mapped;
  }

  async mapFormToAnswer(item: ICustomFormAnswerDTO) {
    const form = await this.customFormsControllerClient.getById(item.customFormId);
    return { ...item, customForm: form };
  }

  async getFormDataToAnswer({
    customFormId,
    customFormLinkId,
    tenant,
  }: {
    customFormId?: number;
    customFormLinkId?: number;
    tenant: ITenantDTO;
  }): Promise<ICustomFormAnswerPreparationVm> {
    const resp: ICustomFormAnswerPreparationVm = { errors: [], app: PbdModule.None };
    const errors: ValidationResult[] = [];
    if (customFormLinkId) {
      resp.customFormLink = await this.customFormLinksControllerClient.getById(Number(customFormLinkId));
      resp.customForm = await this.customFormsControllerClient.getById(resp.customFormLink.customFormId);
    } else if (customFormId) {
      resp.customForm = await this.customFormsControllerClient.getById(customFormId);
    } else {
      throw Error("Not implemented");
    }
    if (resp.customForm.accessOnlyOnInvite) {
      const invitations = await this.customFormsControllerClient.getInvitationsByCustomFormId(resp.customForm.id);
      if (!invitations.find((x) => x.invitedTenant?.id == tenant.id)) {
        errors.push(new ValidationResult("Form only accessible on invite"));
      }
    }
    if (resp.customForm.uniqueSubmitPerTenant) {
      resp.customFormAnswers = await this.customFormAnswersControllerClient.getAllQuery({
        customFormId: [resp.customForm.id],
        createdById: [tenant.id],
      });
      if (resp.customFormAnswers.length > 0) {
        errors.push(new ValidationResult("This form must only be submitted once per person."));
      }
    }
    const resp2 = await this.getConnectedObject(resp, tenant);

    return {
      ...resp,
      connectedAppItem: resp2?.connectedAppItem,
      sourceUrl: resp2?.sourceUrl,
      errors: errors.length > 0 ? errors : undefined,
      app: resp2?.app ?? PbdModule.None,
    };
  }

  async getConnectedObject(
    data: ICustomFormAnswerPreparationVm,
    tenant: ITenantDTO,
  ): Promise<
    { connectedAppItem: IHaveTags; sourceUrl: string; errors?: ValidationResult[]; app: PbdModule } | undefined
  > {
    if (data.customFormLink?.link && data.customForm) {
      const connectionSource = JsonHelpers.parseToCamelCase<CustomFormAnswerSource>(data.customFormLink.link);
      switch (connectionSource.objectName) {
        case "Training": {
          const training = await this.trainingControllerClient.getById(connectionSource.objectId);
          const attendees = await this.trainingControllerClient.getAttendeesByTrainingId(connectionSource.objectId);
          const canFillOut = CustomFormConnectionService.canFillOut(data.customFormLink, tenant, attendees, training);
          if (!canFillOut.success) {
            if (data.errors) {
              data.errors.push(new ValidationResult("Not allowed based on the connected element"));
            } else {
              data.errors = [new ValidationResult("Not allowed based on the connected element")];
            }
          }
          return {
            sourceUrl: TrainingRoutePaths.EditPage,
            connectedAppItem: training,
            errors: data.errors,
            app: PbdModule.TrainingManagement,
          };
        }
        case "Audit": {
          const training = await this.auditControllerClient.getById(connectionSource.objectId);
          const attendees = await this.auditControllerClient.getAttendees(connectionSource.objectId);
          const canFillOut = CustomFormConnectionService.canFillOut(data.customFormLink, tenant, attendees, training);
          if (!canFillOut.success) {
            if (data.errors) {
              data.errors.push(new ValidationResult("Not allowed based on the connected element"));
            } else {
              data.errors = [new ValidationResult("Not allowed based on the connected element")];
            }
          }
          return {
            sourceUrl: AuditRoutePaths.EditPage,
            connectedAppItem: training,
            errors: data.errors,
            app: PbdModule.AuditManagement,
          };
        }
        default:
          throw Error("Not implemented");
      }
    }
    return undefined;
  }

  getDistinctSourceUrls(items: ICustomFormAnswerVm[]) {
    const sourceUrls = items.filterMap((x) => x.sourceUrl);
    return uniq(sourceUrls);
  }

  async getSourceInfoFromSourceUrl(sourceUrl?: string, answers?: ICustomFormAnswerVm[]) {
    const sourceItem = JsonHelpers.parse<CustomFormAnswerSourceItem>(sourceUrl ?? "{}");
    if (sourceItem.objectName == "Training") {
      sourceItem.navigationToSourceUrl = TrainingRoutePaths.EditPage.replace(":id", sourceItem.objectId.toString());
      sourceItem.object = await this.trainingControllerClient.getById(sourceItem.objectId);
    }
    if (sourceItem.objectName == "Audit") {
      sourceItem.navigationToSourceUrl = AuditRoutePaths.EditPage.replace(":id", sourceItem.objectId.toString());
      sourceItem.object = await this.auditControllerClient.getById(sourceItem.objectId);
    }
    if (answers) {
      sourceItem.numberOfTimes = answers.filter((s) => s.sourceUrl == sourceUrl).length;
    }
    return sourceItem;
  }

  async getSourceInfoFromAnswers(answers: ICustomFormAnswerVm[]) {
    const sourceItems: CustomFormAnswerSourceItem[] = [];
    const distinctUrls = this.getDistinctSourceUrls(answers);

    for (const url of distinctUrls) {
      const info = await this.getSourceInfoFromSourceUrl(url, answers);
      sourceItems.push(info);
    }
    return sourceItems;
  }

  exportFormToCsv(form: ICustomFormDTO, items: ICustomFormAnswerVm[]) {
    const it = items.filter((x) => x.customFormId === form.id);

    ExportService.exportCSV(`FilledOut_Forms_${form.title}`, it, (x) => {
      const base: ExportType = {
        id: x.id,
        title: x.customForm.title,
        createdBy: x.createdBy?.fullName,
        submittedAt: x.submittedAt,
      };

      x.customForm.customFields.forEach((cf) => {
        const fieldAnswer = x.formFields.find((x) => x.id == cf.id);
        base[cf.name] = fieldAnswer?.value ?? "";
      });

      return base;
    });
  }

  exportToCsv(items: ICustomFormAnswerVm[]) {
    //TODO2
    const uniqueForms = uniqBy(items, (x) => x.customForm.id).map((x) => x.customForm);
    uniqueForms.forEach((form) => {
      this.exportFormToCsv(form, items);
    });
  }

  convertSourceToJson(objectName: string, objectId: number) {
    //TODO
    const data: CustomFormAnswerSource = {
      objectName,
      objectId,
    };
    return data;
  }

  static getEvaluation(item: ICustomFormAnswerVm) {
    const totalPoints = CustomFormService.getTotalPoints(item.customForm);
    const formPoints = this.getFormPoints(item.evaluation?.ratingFields ?? []);
    const percentage = formPoints / totalPoints;
    let isQuizPassed = percentage >= (item.customForm.evaluationSchema?.percentToPass ?? 0);
    if (item.evaluation?.isPassedManualOverride != undefined) {
      isQuizPassed = item.evaluation.isPassedManualOverride;
    }
    const isQuizFailed = !isQuizPassed;
    return { totalPoints, formPoints, percentage, isQuizPassed, isQuizFailed };
  }

  static getFormPoints(items: IRatingFieldDTO[]): number {
    return items.reduce((pv, cv) => pv + cv.score, 0);
  }

  static isPassedAutomatically(form: ICustomFormDTO, ratings: IRatingFieldDTO[]) {
    const formPoints = this.getFormPoints(ratings);
    const totalPoints = CustomFormService.getTotalPoints(form);
    const percentage = formPoints / totalPoints;
    return percentage >= (form.evaluationSchema?.percentToPass ?? 0);
  }

  static canUseManualEvaluation(answer: ICustomFormAnswerVm, meAsUser: MeAsUser) {
    if (hasRole(meAsUser, [PbdRoles.CustomForms_ModuleAdmin])) {
      return true;
    } else if (answer.customForm.responsible.id == meAsUser.tenant.id) {
      return true;
    }
    return false;
  }

  static canAddQualification(
    answer: ICustomFormAnswerVm,
    qualification: IQualificationDTO,
    availableQualification?: ITenantQualificationDTO,
  ) {
    if (!availableQualification) return true;
    if (!qualification.connectionCondition?.tenantQualificationConnection?.isSelfAssignmentEnabled) return false;
    if (
      answer.submittedAt &&
      availableQualification.lastInspection &&
      answer.submittedAt > availableQualification.lastInspection
    ) {
      return true;
    }
    return false;
  }
}
