import React from "react";
import { ButtonGroup, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useSWR from "swr";

import { ICostTemplateDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { BaseTableEntityProps } from "../../../Models/BaseClasses/BaseTableEntityProps";
import { useTryDelete } from "../../../pbdServices/services/Api/api-hooks";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import { BaseTable } from "../../boilerplate/baseTable";
import ContextMenuButton from "../../shared/components/buttons/contextMenuButton";
import ToggleEditModeButton from "../../shared/components/buttons/toggleEditModeButton";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import DefaultDescriptionList from "../../shared/components/lists/defaultDescriptionList";
import DescriptionListItem from "../../shared/components/lists/descriptionListItem";
import { TableClickCommand } from "../../shared/components/tables/TableClickCommand";
import { useToggle } from "../../shared/hooks/useToggle";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import EditFormCostTemplate from "../components/editFormCostTemplate";

const EditPageCostTemplate: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useTypedParams(["id"]);
  const navigate = useNavigate();
  const { costTemplatesApi } = useAPIs();
  const [editMode, toggleEditMode] = useToggle();
  const [selected, setSelected] = React.useState<BaseTableEntityProps[]>([]);

  const { data, mutate } = useSWR(["/api/costTemplates", id], () => costTemplatesApi.getById(id));

  const tryToDelete = useTryDelete<ICostTemplateDTO, string>(
    (val) => costTemplatesApi.delete(val.id),
    [costTemplatesApi],
    () => navigate(AdminRoutePaths.GeneralConfiguration),
  );

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;
    switch (action) {
      case "Delete":
        submitDeleteRequest(selected);
        break;
      default:
        throw Error("Not implemented");
    }
  };

  async function submitDeleteRequest(dto: BaseTableEntityProps[]) {
    for (const element of dto) {
      const resp = await wrapApiCallWithToast(() => costTemplatesApi.deleteItem(id, element.id));
    }
    setSelected([]);
    mutate();
  }

  const handleSuccess = () => {
    toggleEditMode();
    mutate();
  };

  return (
    <React.Fragment>
      <Card className="mb-3">
        {data && (
          <Card.Body>
            <div className="d-flex justify-content-between">
              <Card.Title as="h5">{data.title}</Card.Title>
              <div>
                <ButtonGroup>
                  <ToggleEditModeButton onClick={toggleEditMode} active={editMode} />
                  <ContextMenuButton
                    actions={[
                      {
                        id: "delete",
                        icon: qmBaseIcons.Delete,
                        label: t("Delete"),
                        onClick: () => tryToDelete(data),
                        // disabled: !hasRole(meAsUser, [
                        //   PbdRoles.QualificationMatrix_Settings,
                        //   PbdRoles.QualificationMatrix_ModuleAdmin,
                        // ]),
                      },
                    ]}
                  />
                </ButtonGroup>
              </div>
            </div>
            {editMode && (
              <EditFormCostTemplate
                itemToUpdate={data}
                onSubmit={(dto) => costTemplatesApi.edit(data.id, dto)}
                onSuccess={handleSuccess}
              />
            )}
            {!editMode && data && (
              <DefaultDescriptionList item={data} includeTitle>
                <DescriptionListItem label={t("Connected entities")} value={data.connectedEntities?.join()} />
              </DefaultDescriptionList>
            )}
            <h5>{t("Template items")}</h5>
            <hr />
            <BaseTable
              tableRows={data.items.map((x) => {
                return {
                  title: x.title,
                  id: x.id,
                  linkTo: AdminRoutePaths.EditCostTemplateItem.replace(":id", x.id).replace(":costTemplateId", id),
                };
              })}
              availableTableActions={["Delete"]}
              onClick={handleTableClick}
              setSelected={setSelected}
              onCreateClick={() => navigate(AdminRoutePaths.CreateCostTemplateItem.replace(":costTemplateId", id))}
            />
          </Card.Body>
        )}
      </Card>
    </React.Fragment>
  );
};
export default EditPageCostTemplate;
