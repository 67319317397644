import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button, FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import {
  ISmartViewCreateDTO,
  ISmartViewDTO,
  PbdModule,
  SmartViewCreateDTO,
  SmartViewEditDTO,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import SmartViewService from "../../../../pbdServices/services/smart-views/smart-view-service";
import CancelButton from "../buttons/cancelButton";
import { FormikSelectInput } from "../forms/formik/formikSelectInput";
import { FormikTextInput, FormikTextInputGroup } from "../forms/formik/formikTextInput";
import FormikValidationSummary from "../forms/formik/formikValidationSummary";

const nameof = nameofFactory<ISmartViewCreateDTO>();

/**
 * This local interface is needed to overcome the shortcomings of html select.
 * It can only be string and no boolean value
 */
interface ISmartViewCreateDTOForm extends Omit<ISmartViewCreateDTO, "isShared"> {
  isShared: string;
}

const ValidationSchema: yup.ObjectSchema<ISmartViewCreateDTOForm> = yup.object({
  name: yup.string().required().min(2).max(250),
  isShared: yup.string().required(),
  query: yup.string().required(),
  description: yup.string(),
});

interface IProps {
  toggleModal: () => void;
  itemToUpdate?: ISmartViewDTO;
  module: PbdModule;
  onSuccess: () => void;
}

function SmartViewForm(props: IProps) {
  const { itemToUpdate, module, toggleModal, onSuccess } = props;
  const { smartViewsApi } = useAPIs();
  const { t } = useTranslation();
  const location = useLocation();

  const submitter = useFormikAPISubmitter<ISmartViewCreateDTOForm>(
    async (values) => {
      if (itemToUpdate) {
        await smartViewsApi.update(
          module,
          itemToUpdate.id,
          new SmartViewEditDTO({ id: itemToUpdate.id, ...values, isShared: values.isShared == "true" }),
        );
      } else {
        await smartViewsApi.create(module, new SmartViewCreateDTO({ ...values, isShared: values.isShared == "true" }));
      }
    },
    [itemToUpdate, module, smartViewsApi],
    onSuccess,
  );

  const initialValues: ISmartViewCreateDTOForm = {
    name: itemToUpdate?.name ?? "",
    description: itemToUpdate?.description ?? "",
    isShared: itemToUpdate?.isShared.toString() ?? "false",
    query: itemToUpdate?.query ?? SmartViewService.getInitialQueryValues(location.search),
  };
  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <Form>
          <FormikTextInputGroup name={nameof("name")} />
          <FormGroup>
            <Label for="description">{t("Description")}</Label>
            <Field name="description" component={FormikTextInput} type="textarea" />
          </FormGroup>
          <FormGroup>
            <Label for="isShared">{t("Sharing options")}</Label>
            <Field name="isShared" component={FormikSelectInput}>
              <option value="false">{t("Private - Only for me")}</option>
              <option value="true">{t("Public - Share with whole organisation")}</option>
            </Field>
          </FormGroup>
          <FormikTextInputGroup name={nameof("query")} />
          <FormGroup>
            <CancelButton onClick={toggleModal} />
            <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
              {t("Save")}
            </Button>
          </FormGroup>
          <FormikValidationSummary formikBag={formikBag} />
        </Form>
      )}
    </Formik>
  );
}

export default SmartViewForm;
