import { TFunction } from "i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { ClaimTypeNames } from "../../../Constants/ClaimTypeNames";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { MeAsUser } from "../UserSettings/models/me-as-user";

export class AuthService {
  user: MeAsUser;

  constructor(user: MeAsUser) {
    this.user = user;
  }

  get tenantId() {
    return this.user.tenant.id;
  }

  get tenant() {
    return this.user.tenant;
  }

  get isInDev() {
    return this.user.roles.includes(PbdRoles.Dev);
  }
  get isQmBaseUser() {
    return this.user.user.email?.toUpperCase().endsWith(GlobalQmBaseConstants.QmBaseDomain);
  }
  get isInPreview() {
    return this.user.roles.includes(PbdRoles.Preview);
  }
  get isInReadOnly() {
    return this.getClaim(ClaimTypeNames.ReadOnlyUser) == "True";
  }

  get isProfileMissing(): boolean {
    if (this.user.tenant.id < 0) return true;
    if (!this.user.claims.find((x) => x.claimType == ClaimTypeNames.TenantId)) return true;
    return false;
  }

  getClaim(claimType: string): string | undefined {
    return this.user.user.claims?.find((c) => c.claimType === claimType)?.claimValue;
  }

  static getCapabilitiesOfRole = (role: string) => (role.includes("_") ? role.split("_")[1] : role);
  /**
   * This functions splits predefined roles to translated readable roles
   * @param role ClaimManagement_Admin
   * @param t
   * @returns Reklamationsmanagement Admin
   */
  static convertRoleToHumanReadable = (role: string, t: TFunction) => {
    if (role == PbdRoles.Admin) {
      return "Admin";
    } else if (role.includes("_")) {
      return `${t(role.split("_")[0])} ${t(role.split("_")[1])}`;
    } else {
      return role;
    }
  };

  /**Admin will be automatically included and overrides everything. User has to be in any of these roles */
  hasRole(roles: string[], includeAdmin = true, includeDev = true) {
    if (roles.length == 1 && roles[0] == PbdRoles.Dev && includeDev) {
      return this.isInDev;
    }
    if (includeAdmin) {
      roles.push(PbdRoles.Admin);
    }
    return roles.some((role) => this.user.roles.includes(role));
  }

  hasSufficientAppRights(appInfoPbdModule: PbdModule) {
    const { adminRole, standardRole } = getModuleRoleNames(appInfoPbdModule);
    const result = this.hasRole([standardRole, adminRole], true);
    return result;
  }
}

export const getModuleRoleNames = (module: PbdModule): { adminRole: string; standardRole: string } => {
  switch (module) {
    case PbdModule.Admin:
      return { adminRole: PbdRoles.Admin, standardRole: PbdRoles.Admin };
    case PbdModule.IdeaManagement:
      return { adminRole: PbdRoles.IdeaManagement_ModuleAdmin, standardRole: PbdRoles.IdeaManagement_Standard };
    case PbdModule.AbsencePlanner:
      return { adminRole: PbdRoles.AbsencePlanner_ModuleAdmin, standardRole: PbdRoles.AbsencePlanner_Standard };
    case PbdModule.CustomForms:
      return { adminRole: PbdRoles.CustomForms_ModuleAdmin, standardRole: PbdRoles.CustomForms_Standard };
    case PbdModule.ClaimManagement:
      return { adminRole: PbdRoles.ClaimManagement_ModuleAdmin, standardRole: PbdRoles.ClaimManagement_Standard };
    case PbdModule.Employees:
      return { adminRole: PbdRoles.Employees_ModuleAdmin, standardRole: PbdRoles.Employees_Standard };
    case PbdModule.Blog:
      return { adminRole: PbdRoles.Blog_ModuleAdmin, standardRole: PbdRoles.Blog_Standard };
    case PbdModule.DocumentManagement:
      return { adminRole: PbdRoles.DocumentManagement_ModuleAdmin, standardRole: PbdRoles.DocumentManagement_Standard };
    case PbdModule.KnowledgeBase:
      return { adminRole: PbdRoles.KnowledgeBase_ModuleAdmin, standardRole: PbdRoles.KnowledgeBase_Standard };
    case PbdModule.QualificationMatrix:
      return {
        adminRole: PbdRoles.QualificationMatrix_ModuleAdmin,
        standardRole: PbdRoles.QualificationMatrix_Standard,
      };
    case PbdModule.ProjectAndTaskManagement:
      return {
        adminRole: PbdRoles.ProjectAndTaskManagement_ModuleAdmin,
        standardRole: PbdRoles.ProjectAndTaskManagement_Standard,
      };
    case PbdModule.AuditManagement:
      return { adminRole: PbdRoles.AuditManagement_ModuleAdmin, standardRole: PbdRoles.AuditManagement_Standard };
    case PbdModule.GoalManagement:
      return { adminRole: PbdRoles.GoalManagement_ModuleAdmin, standardRole: PbdRoles.GoalManagement_Standard };
    case PbdModule.Crm:
      return { adminRole: PbdRoles.Crm_ModuleAdmin, standardRole: PbdRoles.Crm_Standard };
    case PbdModule.TrainingManagement:
      return { adminRole: PbdRoles.Trainings_ModuleAdmin, standardRole: PbdRoles.Trainings_Standard };
    case PbdModule.MaintenanceManagement:
      return {
        adminRole: PbdRoles.MaintenanceManagement_ModuleAdmin,
        standardRole: PbdRoles.MaintenanceManagement_Standard,
      };
    case PbdModule.OpportunityManagement:
      return {
        adminRole: PbdRoles.OpportunityManagement_ModuleAdmin,
        standardRole: PbdRoles.OpportunityManagement_Standard,
      };
    case PbdModule.Products:
      return { adminRole: PbdRoles.Products_ModuleAdmin, standardRole: PbdRoles.Products_Standard };
    case PbdModule.DefectManagement:
      return { adminRole: PbdRoles.DefectManagement_ModuleAdmin, standardRole: PbdRoles.DefectManagement_Standard };
    case PbdModule.QualityMonitor:
      return {
        adminRole: PbdRoles.QualityMonitor_ModuleAdmin,
        standardRole: PbdRoles.QualityMonitor_Standard,
      };
    case PbdModule.Settings:
      return {
        adminRole: PbdRoles.Settings_ModuleAdmin,
        standardRole: PbdRoles.Settings_Standard,
      };
    case PbdModule.Store:
      return {
        adminRole: PbdRoles.Dev,
        standardRole: PbdRoles.Dev,
      };
    case PbdModule.Startpage:
      return {
        adminRole: "",
        standardRole: "",
      };
    case PbdModule.Profile:
      return {
        adminRole: "",
        standardRole: "",
      };
    case PbdModule.Dev:
      return {
        adminRole: PbdRoles.Dev,
        standardRole: PbdRoles.Dev,
      };
    default:
      console.warn(module + " not available");
      return {
        adminRole: PbdRoles.Admin,
        standardRole: PbdRoles.Admin,
      };
  }
};
