import { Field, Form, Formik } from "formik";
import i18next from "i18next";
import { nanoid } from "nanoid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { IOpportunityCategoryVariable } from "@generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import { FormikNumberInput } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";

// TODO: Add yup.ObjectSchema
const ValidationSchema = yup.object({
  variableName: yup
    .string()
    .matches(/^\w+$/, i18next.t("Only letters, numbers and underscore allowed"))
    .required()
    .min(2)
    .max(GlobalQmBaseConstants.MaxTitleLength),
  scaleMinimum: yup.number().required().lessThan(yup.ref("scaleMaximum")),
  scaleMaximum: yup.number().required().min(0).moreThan(yup.ref("scaleMinimum")),
  variableId: yup.string().notRequired(),
  description: yup.string(),
  scaleSections: yup.array().notRequired(),
});

const EditFormVariable: React.FC<BaseFormProps<IOpportunityCategoryVariable>> = (props) => {
  const { itemToUpdate, onSubmit, onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IOpportunityCategoryVariable>((val) => onSubmit(val), [onSubmit], onSuccess);

  const initialValues: IOpportunityCategoryVariable = {
    variableId: itemToUpdate?.variableId || nanoid(),
    scaleMinimum: itemToUpdate?.scaleMinimum || 0,
    scaleMaximum: itemToUpdate?.scaleMaximum || 0,
    variableName: itemToUpdate?.variableName || "",
    description: itemToUpdate?.description || "",
    scaleSections: [],
  };

  return (
    <React.Fragment>
      {itemToUpdate ? (
        <h6>
          {t("Edit")}: {itemToUpdate.variableName}
        </h6>
      ) : (
        <h6>{t("New")}</h6>
      )}
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
        {(formikBag) => (
          <Form>
            <FormGroup>
              <Label for="variableName">{t("Title")}</Label>
              <Field name="variableName" component={FormikTextInput} />
            </FormGroup>
            <FormGroup>
              <Label for="scaleMinimum">{t("From")}</Label>
              <Field name="scaleMinimum" component={FormikNumberInput} />
            </FormGroup>
            <FormGroup>
              <Label for="scaleMaximum">{t("To")}</Label>
              <Field name="scaleMaximum" component={FormikNumberInput} />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t("Description")}</Label>
              <Field name="description" component={FormikTextInput} type="textarea" rows={3} />
            </FormGroup>
            <FormGroup>
              <Button color="link" onClick={onCancel}>
                {t("Cancel")}
              </Button>
              <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                {t("Save")}
              </Button>
            </FormGroup>

            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default EditFormVariable;
