import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import IndexPageConnections from "../connections/pages/indexPageConnections";
import EditPageCostTemplateItem from "../costTemplateItem/pages/editPageCostTemplateItem";
import CreatePageCostTemplate from "../costTemplates/pages/createPageCostTemplate";
import EditPageCostTemplate from "../costTemplates/pages/editPageCostTemplate";
import ArticleConfigurationPage from "../moduleSettings/pages/articleConfigurationPage";
import DetailsPageModuleSettings from "../moduleSettings/pages/detailsPageModuleSettings";
import IndexPageModuleSettings from "../moduleSettings/pages/indexPageModuleSettings";
import { PbdRouteModel } from "../router/pbdRouteModel";
import EditPageTemplates from "../templates/pages/editPageTemplates";

import { AdminLayoutWithSubsidebar } from "./adminLayoutWithSubsidebar";
import { AdminRoutePaths } from "./adminRoutePaths";
import AppConfigurationPage from "./pages/configurations/appConfigurationPage";
import ConfigurationsIndex from "./pages/configurations/configurationsIndex";
import CrmConfigurationPage from "./pages/configurations/crmConfigurationPage";
import EmployeeConfigurationPage from "./pages/configurations/employeeConfigurationPage";
import GeneralConfigurationPage from "./pages/configurations/generalConfigurationPage";
import IdeaManagementConfigurationPage from "./pages/configurations/ideaManagementConfigurationPage";
import StartPageConfigurationPage from "./pages/configurations/startPageConfigurationPage";
import CustomizationIndex from "./pages/customizations/customizationIndex";
import LogoPage from "./pages/customizations/logoPage";
import MyInformationPage from "./pages/myInformationPage";
import { PrivacyPage } from "./pages/privacyPage";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Admin],
};

const adminRoutesInternal: RouteObject[] = [
  {
    path: AdminRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homeIndex")),
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.HomePageUsers,
    Component: lazy(() => import("../users/pages/homePageUsers")),
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.UsersIndex,
    Component: lazy(() => import("../users/pages/indexPageUsers")),
  },
  {
    path: AdminRoutePaths.UsersCreate,
    Component: lazy(() => import("../users/pages/createPageUser")),
  },
  {
    path: AdminRoutePaths.UsersCreateBulk,
    Component: lazy(() => import("../users/pages/createPageUserBulk")),
  },
  {
    path: AdminRoutePaths.UsersDetails,
    Component: lazy(() => import("../users/pages/detailsPageUsers")),
  },
  {
    path: AdminRoutePaths.AccountRequestsIndex,
    Component: lazy(() => import("../accountRequests/pages/indexPageAccountRequests")),
  },
  {
    path: AdminRoutePaths.AccountRequestsDetails,
    Component: lazy(() => import("../accountRequests/pages/detailsPageAccountRequest")),
  },
  {
    path: AdminRoutePaths.HomePagePermissions,
    Component: lazy(() => import("./permissions/pages/homePagePermissions")),
  },
  {
    path: AdminRoutePaths.PermissionsPerUser,
    Component: lazy(() => import("./permissions/pages/permissionsPerUser")),
  },
  {
    path: AdminRoutePaths.PermissionsPerUserPerModule,
    Component: lazy(() => import("./permissions/pages/permissionsPerUser")),
  },
  {
    path: AdminRoutePaths.AllowedDomains,
    Component: lazy(() => import("../allowedDomains/pages/allowedDomains")),
  },
  {
    path: AdminRoutePaths.IndexPageEntityPermission,
    Component: lazy(() => import("../access/pages/indexPageEntityPermissions")),
  },
  {
    path: AdminRoutePaths.DetailsPageEntityPermission,
    Component: lazy(() => import("../access/pages/detailsPageEntityPermission")),
  },
  {
    path: AdminRoutePaths.AuditTrail,
    Component: lazy(() => import("../auditTrail/pages/indexPageAuditTrail")),
  },
  {
    path: AdminRoutePaths.AuditTrailDetails,
    Component: lazy(() => import("../auditTrail/pages/detailsPageAuditTrail")),
  },
  {
    path: AdminRoutePaths.SetTenantInSpecialRole,
    Component: lazy(() => import("./pages/gettingStarted/setTenantInSpecialRolePage")),
  },
  {
    path: AdminRoutePaths.SetProductOwner,
    Component: lazy(() => import("./pages/gettingStarted/setProductOwnerPage")),
  },
  {
    path: AdminRoutePaths.IdeaManagementConfiguration,
    Component: () => <AdminLayoutWithSubsidebar Component={IdeaManagementConfigurationPage} />,
  },
  {
    path: AdminRoutePaths.ArticleConfigurationPage,
    Component: () => <AdminLayoutWithSubsidebar Component={ArticleConfigurationPage} />,
  },
  {
    path: AdminRoutePaths.ConfigurationsStartpage,
    Component: () => <AdminLayoutWithSubsidebar Component={StartPageConfigurationPage} />,
  },
  {
    path: AdminRoutePaths.EmployeeConfiguration,
    Component: () => <AdminLayoutWithSubsidebar Component={EmployeeConfigurationPage} />,
  },
  {
    path: AdminRoutePaths.CrmConfiguration,
    Component: () => <AdminLayoutWithSubsidebar Component={CrmConfigurationPage} />,
  },
  {
    path: AdminRoutePaths.GeneralConfiguration,
    Component: () => <AdminLayoutWithSubsidebar Component={GeneralConfigurationPage} />,
  },
  {
    path: AdminRoutePaths.CreateCostTemplate,
    Component: () => <AdminLayoutWithSubsidebar Component={CreatePageCostTemplate} />,
  },
  {
    path: AdminRoutePaths.EditCostTemplate,
    Component: () => <AdminLayoutWithSubsidebar Component={EditPageCostTemplate} />,
  },
  {
    path: AdminRoutePaths.EditCostTemplateItem,
    Component: () => <AdminLayoutWithSubsidebar Component={EditPageCostTemplateItem} />,
  },
  {
    path: AdminRoutePaths.AppConfigurationPage,
    Component: () => <AdminLayoutWithSubsidebar Component={AppConfigurationPage} />,
  },
  {
    path: AdminRoutePaths.HomePageIntegrations,
    Component: lazy(() => import("./pages/integrations/homePageIntegrations")),
  },
  {
    path: AdminRoutePaths.DetailsPageIntegrations,
    Component: lazy(() => import("./pages/integrations/detailsPageIntegration")),
  },
  {
    path: AdminRoutePaths.CreatePageIntegrations,
    Component: lazy(() => import("./pages/integrations/createPageIntegration")),
  },
  {
    path: AdminRoutePaths.DetailsPageInternalIntegrations,
    Component: lazy(() => import("./pages/integrations/detailsPageIntegrationCalendarAndReminder")),
  },
  {
    path: AdminRoutePaths.IndexPageSyncHistory,
    Component: lazy(() => import("./syncHistory/pages/indexPageSyncHistory")),
  },
  {
    path: AdminRoutePaths.DetailsPageSyncHistory,
    Component: lazy(() => import("./syncHistory/pages/detailsPageSyncHistory")),
  },
  {
    path: AdminRoutePaths.HomePageBilling,
    Component: lazy(() => import("./billing/pages/billingIndexPage")),
  },
  {
    path: AdminRoutePaths.EmailIndex,
    Component: lazy(() => import("../emails/indexPageEmail")),
  },
  {
    path: AdminRoutePaths.ImportHomePage,
    Component: lazy(() => import("../import/pages/importHomePage")),
  },
  {
    path: AdminRoutePaths.ArticleImportPage,
    Component: lazy(() => import("../import/pages/articleImportPage")),
  },
  {
    path: AdminRoutePaths.ImportDetailsPage,
    Component: lazy(() => import("../import/pages/detailsPageImport")),
  },
  {
    path: AdminRoutePaths.IndexPageFiles,
    Component: lazy(() => import("../files/pages/indexPageFiles")),
  },
  {
    path: AdminRoutePaths.DetailsPageFiles,
    Component: lazy(() => import("../files/pages/detailsPageFiles")),
  },
  {
    path: AdminRoutePaths.IndexPageBackup,
    Component: lazy(() => import("./pages/indexPageBackup")),
  },
  {
    path: AdminRoutePaths.IndexPageRoles,
    Component: lazy(() => import("./roles/pages/indexPageRoles")),
  },
  {
    path: AdminRoutePaths.DetailsPageRoles,
    Component: lazy(() => import("./roles/pages/detailsPageRoles")),
  },
  {
    path: AdminRoutePaths.CreatePageRoles,
    Component: lazy(() => import("./roles/pages/createPageRoles")),
  },
  {
    path: AdminRoutePaths.IndexPageGroups,
    Component: lazy(() => import("./groups/pages/indexPageGroups")),
  },
  {
    path: AdminRoutePaths.DetailsPageGroups,
    Component: lazy(() => import("./groups/pages/detailsPageGroups")),
  },

  {
    path: AdminRoutePaths.CreatePageGroups,
    Component: lazy(() => import("./groups/pages/createPageGroups")),
  },
  {
    path: AdminRoutePaths.CreatePageTemplates,
    Component: () => <AdminLayoutWithSubsidebar Component={EditPageTemplates} />,
  },
  {
    path: AdminRoutePaths.EditPageTemplates,
    Component: () => <AdminLayoutWithSubsidebar Component={EditPageTemplates} />,
  },
  {
    path: AdminRoutePaths.CustomizationsLogoPage,
    Component: () => <AdminLayoutWithSubsidebar Component={LogoPage} />,
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.ConfigurationsIndex,
    Component: () => <AdminLayoutWithSubsidebar Component={ConfigurationsIndex} />,
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.IndexPageCustomization,
    Component: () => <AdminLayoutWithSubsidebar Component={CustomizationIndex} />,
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.IndexPageModuleSettings,
    Component: () => <IndexPageModuleSettings />,
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.DetailsPageModuleSettings,
    Component: () => <DetailsPageModuleSettings />,
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.IndexPageConnections,
    Component: () => <IndexPageConnections />,
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.ContactPage,
    Component: () => <MyInformationPage />,
    ...defaultRouteParams,
  },
  {
    path: AdminRoutePaths.PrivacyPage,
    element: <PrivacyPage />,
  },
];

export const adminRoutesConfig: RouteObject[] = adminRoutesInternal.map((x) => {
  return { ...x, ...defaultRouteParams };
});
