import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormGroup, FormTextProps, Label } from "reactstrap";

import { CostTemplateItem, EntityKey, ICostTemplateDTO, ICostTemplateItem } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { CalculationService } from "../../../pbdServices/services/Calculator/calculationService";
import customNanoId from "../../../services/utils";
import CancelButton from "../../shared/components/buttons/cancelButton";
import { FormikCheckboxInput } from "../../shared/components/forms/formik/formikCheckboxInput";
import FormikDebugInfo from "../../shared/components/forms/formik/formikDebugInfo";
import FormikSubmitButton from "../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInput } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { CostCategorySelect } from "../../shared/components/inputControl/select/costCategorySelect";
import { CostCenterSelect } from "../../shared/components/inputControl/select/costCenterSelect";
import { toastError } from "../../shared/components/toasts/toastError";
import { toastSuccess } from "../../shared/components/toasts/toastSuccess";

const nameof = nameofFactory<ICostTemplateItem>();

interface IProps {
  item: ICostTemplateDTO;
  itemToUpdate?: ICostTemplateItem;
  onCancel: () => void;
  onSuccess: () => void;
}

function CostTemplateItemForm(props: IProps) {
  const { t } = useTranslation();
  const { item, itemToUpdate, onCancel, onSuccess } = props;
  const { costTemplatesApi } = useAPIs();
  const [formulaValid, setFormulaValid] = React.useState(false);

  const validateFormula = (dto: ICostTemplateDTO, formula?: string) => {
    setFormulaValid(false);
    if (!formula) {
      toastError(t("Missing formula"));
      return;
    }
    const vars: Record<string, number> = {};
    for (const entry of dto.items) {
      vars[entry.id] = Math.floor(Math.random() * 10) + 1;
    }

    const result = CalculationService.safeCalculationOfFormula(formula, vars);
    if (result.isOk) {
      toastSuccess(t("Valid"));
      setFormulaValid(true);
    } else {
      //@ts-expect-error Error goes away in strict mode
      toastError(t("No calculation possible " + result.error));
    }
  };

  const submitter = useFormikAPISubmitter<ICostTemplateItem>(
    (val) => costTemplatesApi.addOrUpdateTemplateItem(item.id, val.id, new CostTemplateItem(val)),
    [costTemplatesApi, item.id],
    onSuccess,
  );

  const initialValues: ICostTemplateItem = {
    // Workaround to make sure variables to not start with number which leads to problems with the evaluation of the formulas.
    id: itemToUpdate?.id ?? customNanoId(10),
    title: itemToUpdate?.title ?? "",
    isCalculatedValue: itemToUpdate?.isCalculatedValue ?? false,
    canBeOverriden: itemToUpdate?.canBeOverriden ?? false,
    isRequired: itemToUpdate?.isRequired ?? false,
    formula: itemToUpdate?.formula ?? "",
    costCategoryId: itemToUpdate?.costCategoryId ?? undefined,
    costCenterId: itemToUpdate?.costCenterId ?? undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitter}
      //   validationSchema={ValidationSchema}
    >
      {(formikBag) => (
        <Form>
          <FormikDebugInfo formikBag={formikBag} />
          <FormGroup>
            <Label for={"title"}>{t("Title")}</Label>
            <Field name={"title"} component={FormikTextInput} />
          </FormGroup>
          <FormGroup>
            <Field
              name={nameof(`isCalculatedValue`)}
              id="isCalculatedValue"
              component={FormikCheckboxInput}
              label={t("Calculated value?")}
            />
          </FormGroup>
          {formikBag.values.isCalculatedValue && (
            <FormGroup>
              <Label for={"formula"}>{t("Formula")}</Label>
              <InputGroup>
                <Field name={"formula"} component={FormikTextInput} />
                <Button onClick={() => validateFormula(item, formikBag.values.formula)} variant="outline-primary">
                  {t("Validate formula")}
                  {formulaValid && <qmBaseIcons.Check color="success" className="ms-2" />}
                </Button>
              </InputGroup>
            </FormGroup>
          )}
          <FormGroup>
            <Field name={`isRequired`} id="isRequired" component={FormikCheckboxInput} label={t("Required")} />
          </FormGroup>

          <FormGroup>
            <Label>{t("Category")}</Label>
            <Field name="costCategoryId">
              {({ field, form: { setFieldValue, setFieldTouched } }: FieldProps<string> & FormTextProps) => (
                <>
                  <CostCategorySelect
                    onChange={(title, id) => {
                      setFieldValue(field.name, id);
                    }}
                    isClearable
                    defaultValue={
                      formikBag.values.costCategoryId
                        ? { id: formikBag.values.costCategoryId, title: "", entityKey: EntityKey.CostCategory }
                        : undefined
                    }
                    onBlur={() => setFieldTouched(field.name)}
                  />
                </>
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Label>{t("Cost center")}</Label>
            <Field name="costCenterId">
              {({ field, form: { setFieldValue, setFieldTouched }, meta }: FieldProps<string> & FormTextProps) => (
                <>
                  <CostCenterSelect
                    onChange={(title, id) => {
                      setFieldValue(field.name, id);
                    }}
                    isClearable
                    defaultValue={
                      formikBag.values.costCenterId
                        ? { id: formikBag.values.costCenterId, title: "", entityKey: EntityKey.CostCenter }
                        : undefined
                    }
                    onBlur={() => setFieldTouched(field.name)}
                  />
                </>
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <CancelButton onClick={onCancel} />
            <FormikSubmitButton formikBag={formikBag} />
          </FormGroup>

          <FormikValidationSummary formikBag={formikBag} />
        </Form>
      )}
    </Formik>
  );
}
export default CostTemplateItemForm;
